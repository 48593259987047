@import "@styles/variables";
.preview-image-modal {
  & .modal-dialog {
    padding: 0px !important;
  }
  & .modal-content {
    background-color: transparent !important;

    & .preview-image-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      & .preview-image-box {
        background-color: #ffffff;
        border-radius: 8px;

        & .preview-image-box-header {
          border-radius: 8px 8px 0 0;
          background-color: #fafafc;
          padding: 10px 15px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border-bottom: solid 1px #eaecf0;
          height: 62px;
          gap: 10px;

          & .preview-image-box-header-title {
            font-weight: 450;
            font-size: 14px;
            margin: 0;
            padding: 0;
            line-height: normal;
            color: #667085;
            letter-spacing: normal;

            &.ellipsis-text {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        & .preview-image-box-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 15px;
          transition: width 0.5s ease-in-out, height 0.5s ease-in-out,
            transform 0.5s ease-in-out;

          & .image-container {
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-bottom: 10px;
            min-width: 150px;
            @media (max-width: 500px) {
              min-height: 100px !important;
            }
            @media (min-width: 500px) {
              min-height: 250px;
            }
            transition: width 0.5s ease-in-out, height 0.5s ease-in-out,
              transform 0.5s ease-in-out;

            & img {
              border-radius: 7px !important;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            & .custom-img {
              border-radius: 7px !important;
              background-color: transparent !important;
            }
          }

          & .disable-zoom {
            & figure {
              pointer-events: none;
              user-select: none;
            }
          }

          & .image-controls-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;

            & .image-list-container {
              height: 40px;

              .swiper-container {
                height: 100%;
                padding: 0px 15px;

                .swiper-wrapper {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                }

                .image-item {
                  width: 40px !important;
                  height: 40px !important;
                  background-color: #ffffff;
                  border-radius: 8px;
                  cursor: pointer;

                  &.right-space {
                    margin-right: 10px;
                  }

                  &.left-space {
                    margin-left: 10px;
                  }

                  .img {
                    width: 40px;
                    height: 40px;
                    border-radius: 8px;
                    object-fit: cover;

                    &.active {
                      border: solid 3px rgb(52, 127, 212);
                    }
                  }
                }
              }
            }

            & .image-controls {
              background-color: #ecf7ff;
              border-radius: 15px;
              padding: 0px 24px;
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 24px;
              height: 40px;

              & .separator {
                display: block;
                width: 0.3px;
                height: 15px;
                background-color: #007aff;
                border-radius: 999px;
                outline: solid 0.7px #007aff;
              }

              & button {
                margin: 0;
                padding: 0;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
