.custom-top-10 {
    top: 69px !important;
    left: 240px !important;
    color:#0080FC !important;
    background-color: #ECF7FF !important;
    border: none !important;
    border-radius: 50px !important;
    font-weight: 500 !important;
  }
  .line{
    color: #0080FC !important;
  }

  .bg{
    background-color: #F6F6F6 !important;
    
  }

  .custom-top-20{
    top: 182px !important;
    left: 240px !important;
    color:#0080FC !important;
    background-color: #ECF7FF !important;
    border: none !important;
    border-radius: 50px !important;
    font-weight: 500 !important; 
  }
  .custom-top-30{
    top: 295px !important;
    left: 240px !important;
    color:#0080FC !important;
    background-color: #ECF7FF !important;
    border: none !important;
    border-radius: 50px !important;
    font-weight: 500 !important; 
  }

  .gapp{
    gap: 14px !important;
  }

.line-container {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}
.line-container::before{
  content: '';
  flex: 1;
  height: 1px;
  background-color: #99D5FF;
  margin-right : 10px;
}
.line-container::after {
    content: '';
    flex: 1;
    height: 1px;
    background-color: #99D5FF;
    margin-left: 10px;
}
.line-text {
    /* padding: 8px 16px; */
    font-size: 14px;
    font-weight: 500;
    border-radius: 100px;
    color:#0080FC !important;
    background-color: #ECF7FF; 
}