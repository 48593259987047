@import "@styles/variables";
@import '@styles/variables';
@import '@styles/mixins';

.custom-upload-image-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 2px 0px 4px 0px #0000001a;

  @media (min-width: 900px) {
    width: 400px;
    margin: 15px auto;
  }

  .header {
    background-color: #ffffff;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
    border-radius: 8px 8px 0;

    @media (min-width: 900px) {
      background-color: #f9fafb;
    }

    span {
      font-size: 18px;
      font-weight: 500;
    }

    .back-link {
      margin-right: 12px;
      color: $gray7;
      cursor: pointer;
      align-self: center;
    }
  }
  .body {
    height: calc(100% - (60px + 90px));

    .image-container {
      height: calc(100% - 95px);
      padding: 15px;

      .position-relative {
        height: 100%;

        .image {
          overflow: hidden;
          height: 100%;
          width: 100%;
          position: relative;
          border-radius: 8px;
          background-color: #f2f4f7;

          .upload-image-div {
            border-radius: 8px;
            height: 100%;
            width: 100%;

            .upload-image-child {
              background-color: #f2f4f7;
              border: 1px dashed #d0d0d0;
              height: 100%;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-wrap: wrap;
              border-radius: 8px;
              cursor: pointer;
              font-size: 17px;
              color: #667085;
            }

            .img-fluid {
              border-radius: 8px;
              object-fit: contain;
            }
          }
        }

        .close-btn-div {
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
          background-color: rgba($color: #000000, $alpha: 0.2);
          backdrop-filter: blur(8px);
          width: 40px;
          height: 40px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-radius: 999px;

          svg {
            width: 22px;
            height: 22px;
          }

          path {
            fill: #ffffff;
          }
        }
      }
    }
    .image-list-container {
      height: 95px;

      .swiper-container-center {
        .swiper-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
      }

      .swiper-container-left {
        .swiper-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: left;
        }
      }

      .swiper-container {
        height: 100%;
        padding: 0px 15px;

        .image-item {
          width: 75px !important;
          height: 75px !important;
          background-color: #ffffff;
          border-radius: 8px;
          cursor: pointer;

          &.right-space {
            margin-right: 10px;
          }

          &.left-space {
            margin-left: 10px;
          }

          &.add-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background-color: #ffffff;
            border: solid 1px rgba(128, 128, 128, 0.5);

            span {
              font-weight: 500px;
              font-size: 40px;
              color: rgba(128, 128, 128, 0.5);
            }
          }
          .img {
            width: 75px;
            height: 75px;
            border-radius: 8px;
            object-fit: cover;

            &.active {
              border: solid 3.7px rgba(0, 128, 252, 0.4);
            }
          }
        }
      }
    }
  }
  .footer {
    height: 90px;
    background-color: #e1effe;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    border-radius: 20px 20px 0px 0px;
    padding: 0px 16px 6px 16px;

    @media (min-width: 900px) {
      background-color: #f9fafb;
      border-radius: 0px 0px 8px 8px;
    }

    .btn-retake {
      border: 1px solid #eaecf0;
      padding: 10px 16px 10px 16px;
      border-radius: 8px;
      font-weight: 600;
      background: #ffffff;
      color: #1d2939;
      font-size: 14px;
      font-weight: 600;
      height: 43px;
      outline: none;
    }

    .btn-save {
      padding: 10px 16px 10px 16px;
      border: 1px solid transparent;
      border-radius: 8px;
      background-color: #0080fc;
      color: #fff;
      font-family: Graphik;
      font-weight: 600;
      font-size: 14px;
      height: 43px;
      outline: none;
    }
  }
}

.custom-delete-image-modal {
  background-color: rgba(0, 0, 0, 0.2);
  .modal-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: transparent !important;
    box-shadow: none;

    .container.modal-body {
      width: 350px !important;
      max-width: 350px !important;
      background-color: #ffffff;
      border-radius: 10px;
      padding: 16px;

      .icon-container {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .icon {
          background-color: rgba($color: red, $alpha: 0.05);
          width: 55px;
          height: 55px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-radius: 999px;

          svg {
            width: 24px;
            height: 24px;
          }

          path {
            fill: rgba($color: red, $alpha: 0.7);
          }
        }
      }

      .content-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 20px;

        p {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
          color: #000000;
          text-align: center;
          letter-spacing: 0.1px;
        }
      }

      .action-container {
        display: flex;
        flex-direction: row;
        justify-content: end;
        margin-top: 30px;
        gap: 10px;

        .btn-cancel {
          padding: 10px 16px 10px 16px;
          border: 1px solid #eaecf0;
          border-radius: 8px;
          background: #ffffff;
          color: #1d2939;
          font-weight: 500;
          font-size: 14px;
          height: 40px;
          outline: none;
          width: 90px;
        }

        .btn-delete {
          padding: 10px 16px 10px 16px;
          border: 1px solid transparent;
          border-radius: 8px;
          background-color: #0080fc;
          color: #ffffff;
          font-family: Graphik;
          font-weight: 500;
          font-size: 14px;
          height: 40px;
          outline: none;
          width: 90px;
        }
      }
    }
  }
}
// .main-div {
//   width: 100%;
//   overflow: hidden;
//   // height: 736px;
//   top: 149px;
//   left: 483px;
//   gap: 0px;
//   border-radius: 8px;
//   font-family: Graphik;

//   .header {
//     width: 100%;
//     height: 65px;
//     border-radius: 8px 8px 0px 0px;
//     // border: 1px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;

//     span {
//       font-size: 20px;
//       font-weight: 500;
//     }

//     .back-link {
//       margin-right: 12px;
//       color: $gray7;
//       cursor: pointer;
//       align-self: center;
//     }
//   }

//   .body {
//     background-color: #ffffff;
//     width: 100%;

//     .image {
//       overflow: hidden;
//       height: 450px;
//       width: 100%;
//       position: relative;
//       border-radius: 8px;
//       background-color: #f2f4f7;

//       .upload-image-div {
//         border-radius: 8px;
//         height: 100%;
//         width: 100%;

//         .upload-image-child {
//           background-color: #f2f4f7;
//           border: 1px dashed #d0d0d0;
//           height: 100%;
//           width: 100%;
//           display: flex;
//           justify-content: center;
//           align-items: center;
//           flex-wrap: wrap;
//           border-radius: 8px;
//           cursor: pointer;
//           font-size: 17px;
//           color: #667085;
//         }

//         .img-fluid {
//           border-radius: 8px;
//           object-fit: contain;
//         }
//       }

//       // .img-overlay {
//       //     position: absolute;
//       //     overflow: hidden;
//       //     height: 100%;
//       //     width: 100%;
//       //     top: 0;
//       //     left: 0;
//       //     background-color: rgba(0, 0, 0, 0.22);
//       // }
//     }

//     .position-relative {
//       .close-btn-div {
//         position: absolute;
//         top: 15px;
//         right: 15px;
//         cursor: pointer;
//       }
//     }
//     .image-list-container {
//       height: 100px;
//       background-color: red;
//     }

//     .bottom-btns {
//       height: 50px;
//       background-color: green;
//       align-items: end;
//     }

//     .row {
//       div {
//         .btn-retake {
//           border: 1px solid #eaecf0;
//           padding: 10px 16px 10px 16px;
//           border-radius: 8px;
//           font-weight: 600;
//           background: #ffffff;
//           color: #1d2939;
//           font-size: 14px;
//           font-weight: 600;
//           // margin-right: 6px;
//         }

//         .btn-save {
//           padding: 10px 16px 10px 16px;
//           border: 1px solid transparent;
//           border-radius: 8px;
//           background-color: #0080fc;
//           color: #fff;
//           font-family: Graphik;
//           font-weight: 600;
//           font-size: 14px;
//           // margin-left: 12px;
//         }
//       }
//     }
//   }
// }

// @include respond-to('tablet-landscape') {
//   .main-div {
//     font-size: 16px;
//   }

//   .header {
//     font-weight: 600;
//   }

//   // .close-btn-div {
//   //     display: none;
//   // }

//   .bottom-btns {
//     position: fixed;
//     bottom: 32px;
//     z-index: 10;
//     left: 50%;
//     transform: translateX(-50%);
//     transform-style: preserve-3d;
//     width: calc(100vw - 32px);
//     max-width: 400px;
//     border-radius: 4px;
//     height: 36px;
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//     flex-wrap: nowrap;
//     padding: 9px 16px;
//     font-weight: 600;
//     font-size: 14px;
//     font-weight: 600 !important;
//     color: white;
//   }

//   .bottom-btns::after {
//     content: '';
//     position: fixed;
//     background: #e1effe;
//     margin: 0 auto;
//     width: 100vw;
//     left: 50%;
//     bottom: -32px;
//     z-index: -1;
//     transform: translateZ(-1px) translateX(-50%);
//     height: 88px;
//     border-radius: 16px 16px 0px 0px;
//   }
// }

// @media (min-width: 767.98px) {
//   .main-div {
//     width: 30%;
//     box-shadow: 2px 0px 4px 0px #0000001a;
//     border: 1px solid #efefef;
//     width: 391px;

//     .header {
//       background: #f9fafb;
//       color: #667085;
//     }
//   }
// }
