@import "@styles/variables";
.grid-container {
  display: grid;
  grid-template-columns: auto 90px 120px;
  gap: 16px;
  align-items: stretch;
}
.btn-container {
  display: grid;
  grid-template-columns: 90px 120px;
  gap: 16px;
}


.pc {
  padding: 10px 16px;
}
.cardApi {
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.2);
  width: 485px;
  overflow: hidden;
}
.cardApiOpen {
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.2);
  margin: 16px 4px 22px 4px;
  width: 567px;
  overflow: hidden;
}
.cardApiOpenHed {
  padding: 25px 16px 20px 16px;
}
.cardApiOpenButton {
  padding: 0 16px 16px 16px;
}
.headerCardApi {
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1);
  background-color: #ffff;
  padding: 25px 16px 16px 16px;
}
.labelPass {
  font-size: 14px;
  line-height: 20px;
  color: #210D4A;
  font-weight: 400;
  padding-bottom: 17px;
}
.inputPassword {
  padding: 10px 12px;
  border: 1px solid #E8EAED;
  border-radius: 8px;
  width: 100%;
  background: none;
  font-size: 14px;
}
.wrapper-btn-pass-api {
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 12px;
  background-color: #FAFAFC;
  box-shadow: -3px 0 3px -1px rgba(16, 24, 40, 0.06), 3px 0 3px -1px rgba(16, 24, 40, 0.06);

}
.btn-pass-api {
    border-radius: 8px;
    border: none;
    background-color: #0080FC;
    padding: 11px 16px;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.btn-open-api {
  border-radius: 8px;
  border: none;
  background-color: #0080FC;
  width: 100%;
  height: 100%;
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.error-msg {
    color: #FF0000;
    font-size: 12px;
    padding-top: 5px;
}
.btn-open-api:hover {
  background-color: #0063D2;
}
.inputPasswordBtn {
  padding: 8px 12px;
  border-top: 1px solid #E8EAED;
  border-right: 1px solid #E8EAED;
  border-bottom: 1px solid #E8EAED;
  border-left: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  background: none;
}
.inputPasswordEye {
  padding: 10px 12px;
  border: 1px solid #E8EAED;
  background: none;
  border-radius: 8px;
  width: 100%;
}
.input-pass-group {
  display: flex;
  flex-direction: row;
}
@media (max-width: 768px) {
  .grid-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
  }
  .btn-container {
    display: grid;
    grid-template-columns: 85px 120px;
    gap: 16px;
  }
  .btn-open-api {
    height: 40px;
  }
  .cardApiOpen {
    width: 100%;
  }
  .cardApi {
    padding: 0 2px;
    width: 100%;
  }
  .btn-oper-m {
    width: 85px;
  }
}
@media (max-width: 768px) {
  .cardApiOpen {
    width: 98%;
    margin: 0 4px 22px 4px;
  }
  .cardApiOpenButton {
    padding: 10px 16px 25px 16px;
  }
}
.toast-centered {
  text-align: center;
}