@import "@styles/variables";
.custom-dropdown-toggle {
  width: 100%;
  text-align: start !important;
  background-color: white !important;
  color: rgb(102, 112, 133) !important;
}

.custom-dropdown-menu {
  width: 50%;
  /* color: black; */
}

.res {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.bor {
  border: unset !important;
}

.bgClass {
  /* color: var(--bs-dropdown-link-hover-color); */
  /* background-color: #c0d9ff !important; */
  background: #f5f6f7 !important;
  color: #0080fc;
}
.bgClass:hover {
  background: #f5f6f7 !important;
  color: #0080fc;
}

/* Small Devices, Tablets */
@media only screen and (max-width: 575px) {
  .addVehicle {
    padding: 0px !important;
    border-radius: 0px !important;
  }
  .addVehicle.bg-blur {
    background-color: #ffffff;
  }
  .addVehicle .modal-content {
    position: absolute;
    bottom: 0;
    @media (max-width: 768px) {
      border-radius: 24px 24px 0 0 !important;
    }
    @media (min-width: 768px) {
      border-radius: 12px !important;
    }
  }
  .addVehicle.modal.show .modal-dialog {
    margin-top: 15px !important;
    padding: 0px;
  }

  .generic-modal-footer2 {
    justify-content: space-between !important;
    background-color: #e1effe;
  }

  .generic_apply_button2 {
    width: 47% !important;
  }
  .edit_cancel_button2 {
    width: 47% !important;
  }

  .vehicle-label {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #475467;
    line-height: normal;
  }

  .modal.addVehicle .modal-dialog {
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    transform: translateY(100px);
    opacity: 0;
  }
  
  .modal.addVehicle.show .modal-dialog {
    transform: translateY(0);
    opacity: 1;
  }
}
