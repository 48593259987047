@import "@styles/variables";
@import '@styles/variables';
@import '@styles/mixins';

.employee-detail-page {
  $radius: 8px;
  border-radius: $radius;

  .content {
    padding: 16px;
    @include flex-container($justify: space-between, $align: flex-start,);
    // gap: 1.125rem;
    gap: 0.8rem;
    flex-wrap: wrap;
    .left-container {
      flex: 2;
    }
    .right-container {
      flex: 1;
    }
  }

  @media (min-width: 900px) {
    height: 100vh;
    overflow-y: scroll;
    .content {
      padding: 16px;
    } 
    &::-webkit-scrollbar {
      width: 3px;
      background-color: #e4e4e4 !important;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #b6b6b6;
      border-radius: 999px !important;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #b6b6b6;
      cursor: pointer;
    }     
  }

  @media (max-width: 901px) {
    .content {
      flex-direction: column !important;
      margin-top: 50px !important;
    }
    .employee-card {
      // width: 100%;
    }
    // .employee-detail-page .content {
    //   gap:0.8rem
    // }
  }
  @media (max-width: 1023px) {
    .content {
      flex-direction: column !important;
    }
    .employee-card {
      width: 100%;
    }
  }
}

@include respond-to('tablet-landscape') {
}

.employee-card {
  width: calc(50% - 8px); 
  // margin: 1%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  // gap: 3px;
  border-radius: 10px;
  border: 1px solid #E6E8EC;
  .details img{
    border-radius: 10px;
  }
  .details {
    width: 100%;
    font-size: 14px;
    display: flex;
    // align-items: center;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px 0px 16px 16px;
    // gap: 10px;
    .name {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .name_text {
        font-size: 14px;
        font-weight: 600;
        color: #1D2939 !important;
      }
    }
    .suspended {
      color: orange;
      // padding: 10px 20px;
      padding: 10px;
      // margin: 20px -1px;
      border-right: 1px solid orange;
      background: rgb(255,255,255); 
      background: linear-gradient(270deg, #FFEECE 0%, rgba(255, 238, 206, 0) 100%);
      font-weight: 450;
      margin-right:-1px
      
    }
    .active {
      color: #12B76A;
      // margin: 20px -1px;
      padding: 10px 20px;
      border-right: 1px solid #12B76A;
      background: rgb(255,255,255); 
      // background: linear-gradient(270deg, #CEF9DE 0%, rgba(206, 249, 222, 0) 100%);
      background: linear-gradient(to right, rgba(206, 249, 222, 0), rgba(206, 249, 222, 1));
      font-weight: 450;
      margin-right:-1px;

    }
  }
  .options {
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 1px solid #e6e8ec;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f9fafb;
  }
  .options button {
    padding: 6px 15px;
    border-radius: 10px;
    font-size: 14px;
  }
  .options button.edit{
    // border: 1.5px solid #33AAFF;
    border: 1px solid #EAECF0;
    background-color: white;
    color: black;
    font-weight: 500;
    height:36px;
    border-radius: 8px;
  }
  .options button.delete{
    border: none;
   background: rgba(238, 106, 95, 0.08);
    color: #EE6A5F;
    font-weight: 500;
    height:36px;
    border-radius: 8px;
  }
  .options button.activate{
    background:rgba(255, 239, 209, 1);
   border: 1px solid rgba(255, 239, 209, 1);
    color:rgba(253, 176, 34, 1);
    font-weight: 500;
    height:36px;
    border-radius: 8px;
  }
  .options button.deactivate{
    background: #D3FAE1; 
    border: 1px solid #D3FAE1;
    color: #12B76A;
    font-weight: 500;
    height:36px;
    border-radius: 8px;
  }

  .employee_image {
    width: 80px;
    height: 80px;
  }

  .employee_number {
    width: 20px;
    height: 20px;
  }

  .gap_employee_number {
    gap: 0.5rem;
  }

  // Media query for mobile devices
  @media (max-width: 768px) {
    // width: 100%; // Full width on mobile devices
 
  }
  @media (max-width: 769px) {
  
  }
  @media (max-width: 1023px) {
 
  }
  @media (max-width: 1440px) {
  
  }
}

.deactivate-modal {
  img {
    border-radius: 100%;
    width: 100px;
  }
}

.delete-modal {
  img {
    border-radius: 100%;
    width: 100px;
  }
}

.information-modal {
  .imp {
    color: red;
  }

  .profile {
    border-radius: 100%;
    width: 70px;
    height: 70px;
  }

  .eye {
    margin-left: -30px;
  }

  .custom-dropdown-toggle {
    width: 100%;
    text-align: start !important;
    background-color: white !important;
    // color: var(--bs-body-color) !important;
    border-color: var(--bs-border-color) !important;
    // line-height: 1.5;
    padding: 0.375rem 0.75rem;
    font-size: 0.85rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .custom-dropdown-menu {
    width: 50%;
    font-size: 0.85rem;
  }

  .res {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
}




