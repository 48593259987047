@import "@styles/variables";
@import '@styles/variables';
@import '@styles/mixins';
@import 'bootstrap/dist/css/bootstrap.min.css';

@media (min-width: 767.98px) {
  .custom-modal-lg {
    --modalContainer: 650px;
    --rowWidth: calc(650px - 16px - 16px);
  }
  .custom-modal-md {
    --modalContainer: 550px;
    --rowWidth: calc(550px - 16px - 16px);
  }
}
@media (min-width: 767.98px) {
  .product-return-table {
    outline: 1px solid $stock1;
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
    // --rowWidth: calc(800px - 16px - 16px);
    position: relative;
    table-layout: fixed;
    width: var(--rowWidth) !important;
    height: calc(100vh - 40px - 54.8px - 65px - 32px);

    thead {
      display: table-header-group;
      background-color: #f9fafb;

      tr {
        display: table-row;
        position: relative;

        th {
          padding: 0px 10px !important;
          height: 45px !important;

          &:first-child {
            @media (min-width: 1200px) {
              padding-left: 20px !important;
            }
          }
          &:last-child {
            @media (min-width: 1200px) {
              padding-right: 20px !important;
            }
          }
          &.checkbox-th {
            min-width: 50px !important;
            max-width: 50px !important;
            width: 50px !important;
          }
          &.damageditem {
            min-width: 40% !important;
            max-width: 40% !important;
            width: 40% !important;
          }
          &.image {
            min-width: 100px !important;
            max-width: 100px !important;
            width: 100px !important;
          }
          &.unit,
          &.price {
            flex: 1 1 auto;
            // min-width: calc((60% - 50px) / 3) !important;
            // max-width: calc((60% - 50px) / 3) !important;
            // width: calc((60% - 50px) / 3) !important;
          }
        }
      }
    }

    tbody {
      display: block;
      height: 100%;
      width: var(--rowWidth) !important;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 3px;
        background-color: #e4e4e4 !important;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #b6b6b6;
        border-radius: 999px !important;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #b6b6b6;
        cursor: pointer;
      }

      tr {
        display: table;
        table-layout: fixed;
        min-width: var(--rowWidth);
      }
      td {
        overflow: hidden;
        padding: 16px 10px !important;

        &:first-child {
          @media (min-width: 1200px) {
            padding-left: 20px !important;
          }
        }
        &:last-child {
          @media (min-width: 1200px) {
            padding-right: 20px !important;
          }
        }
        &.check {
          min-width: 50px !important;
          max-width: 50px !important;
          width: 50px !important;
        }
        &.item {
          min-width: 40% !important;
          max-width: 40% !important;
          width: 40% !important;
        }
        &.image {
          min-width: 100px !important;
          max-width: 100px !important;
          width: 100px !important;
        }
        &.unit,
        &.price {
          flex: 1 1 auto;
          // min-width: calc((var(--rowWidth) * (60 / 100) - 50px) / 3) !important;
          // max-width: calc((var(--rowWidth) * (60 / 100) - 50px) / 3) !important;
          // width: calc((var(--rowWidth) * (60 / 100) - 50px) / 3) !important;
        }
      }
    }
    tr:not(:last-child),
    thead {
      @media (max-width: 767.98px) {
        border: 1px solid $stock1;
      }
      @media (min-width: 767.98px) {
        border-bottom: 1px solid $stock1;
      }
    }
    th {
      // display: flex;
      color: $gray7;
      //text-transform: capitalize;
      background-color: $gray2;
      padding: 12px 24px 12px 24px;
      > span {
        @include flex-container($align: center);
        gap: 5px;
        font: {
          weight: 500;
          size: 12px;
        }
        > svg {
          @include square(16px);
        }
      }
    }
    tr {
      // position: relative;
      // z-index: 100;
      .payment-no {
        a {
          text-decoration: none;
        }
      }
      .payments_btns {
        display: flex;
        align-items: center;
        justify-content: start;
        column-gap: 20px;
      }

      &.active {
        background: #e8f2fd;
        border-color: $primary2;
      }
      td {
        color: $base-black;
        font-size: 14px;
        padding: 0 {
          left: 24px;
          right: 2px;
        }
        height: 80px;
      }
      .modal-header {
        border-bottom: 0px !important;
      }
      .modal-footer {
        border-top: 0px !important;
      }
      .custom-modal {
        .modal-header {
          .modal-title {
            display: flex !important;
            flex-direction: column !important;
            justify-content: center !important;
            align-items: center !important;
            text-align: center !important;
          }
        }
      }
      .check {
        padding: 0;
        // position: relative;
        pointer-events: all;
        label {
          cursor: pointer;
          height: 100%;
          width: 100%;
          // position: absolute;
          top: 0;
          left: 0;
          padding: 26px 0 {
            left: 24px;
          }
        }
      }
      .link-wrapper {
        cursor: pointer;
        display: contents;
      }
      .link-wrapper.mobile-only.payment-row {
        display: none;
      }
    }
    th {
      position: relative;
      // &:not(:first-of-type) {
      // min-width: 112px;
      // }
      &.open {
        &::before {
          content: '';
          position: fixed;
          width: 100vw;
          height: 100vh;
          background: transparent;
          left: 0;
          top: 0;
          z-index: 101;
        }
        .sorting-options {
          display: block;
        }
      }
      max-width: 16.67%;
      > span:not(.unsorted) {
        cursor: pointer;
      }
      .sorting-options {
        display: none;
        background: white;
        top: 36px;
        left: 8px;
        background: white;
        padding: 4px 0;
        border-radius: 6px;
        box-shadow: 4px 4px 16px 0px #00000014;
        border: 1px solid #f6f6f6;
        transition: 0.8s;
        position: absolute;
        z-index: 101;
        div {
          padding: 10px;
          cursor: pointer;
          svg:last-child {
            opacity: 0;
          }
          &.selected {
            background: #f5f6f7;
            svg:last-child {
              opacity: 1;
            }
          }
          svg:first-child {
            margin-right: 27px;
          }
        }
      }
    }
  }
}

.upload-image-no-padding {
  @media (max-width: 900px) {
    margin: -20px -16px;
  }
}
.payment-body {
  background: white;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #eaecf0;
  border-radius: 8px;
  box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.1019607843);
  border: 1px solid #e6e8ec;
  overflow: hidden;
}

.payment-body-head {
  background-color: #ecf7ff;
}

.input-div {
  position: relative;
}

.to-be-collected {
  font-size: 16px;
  font-weight: 500;
}

.input-camara-image {
  position: absolute;
  right: 3px;
  top: 3px;
}

.form-label {
  font-size: 14px !important;
  font-weight: 400 !important;
}

/* Chrome, Safari, Edge, Opera */
.payment-input::placeholder {
  color: #98a2b3 !important;
}

/* Firefox 19+ */
.payment-input::-moz-placeholder {
  color: #98a2b3 !important;
}

/* Internet Explorer 10-11 */
.payment-input:-ms-input-placeholder {
  color: #98a2b3 !important;
}

/* Microsoft Edge */
.payment-input::-ms-input-placeholder {
  color: #98a2b3 !important;
}

.payment-input-productReturn {
  padding: 9px 12px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  // margin-bottom: 6px !important;
  background-color: #f9fafb !important;
  border: 1px solid #eaecf0;
}

.payment-input {
  padding: 9px 12px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  // margin-bottom: 6px !important;
  background-color: #fcfcfd !important;
  border: 1px solid #eaecf0;
  color: #475467;
}

.AddLink {
  cursor: pointer;
  color: #0080fc;
  font-size: 12px;
  padding-left: '24px';
  font-weight: 500;
}

.camara-badge {
  top: 3px;
  right: -1px;
}

.payment-footer {
  background-color: #f9fafb;
  padding: 12px;
}

.btn-payment-save {
  background-color: #d0d5dd;
  padding: 9px 30px !important;
  color: #475467;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
}

.btn-payment-save-blue {
  background-color: #0080fc;
  padding: 9px 30px !important;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  border: none;
}

.payment-footer-mob {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1001;

  .payment-footer-mob-btn {
    background-color: #e1effe;
    padding-bottom: 32px !important;
    border-radius: 20px 20px 0 0;
    box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.1019607843);
  }
}

// .modal_payment {
//   .modal-content {
//     padding: 0 1px;
//     border-radius: 8px;
//     overflow: hidden;
//     border: none !important;

//     // .modal_head_payment {
//     //     padding: 10px 10px;
//     //     border-radius: 0;
//     //     background: #F9FAFB;

//     //     .back-link {
//     //         //   margin-right: 8px;
//     //         //   color: $gray7;
//     //         cursor: pointer;
//     //         padding-bottom: 16px;
//     //         border-bottom: 1px solid #EAECF0;
//     //     }

//     //     .modal-title {
//     //         font-size: 20px;
//     //     }
//     // }

//     .modal-body {
//     }

//     .modal-footer {
//     }

//     .modal_title {
//       color: #1d2939;
//       font-size: 16px;
//       font-weight: 600;
//       display: flex;
//       align-items: center;
//     }

//     .modal_body {
//       padding: 16px !important;
//     }
//   }
// }

.image-upload-no-padding .modal-dialog {
  padding: 0 !important;
}

.custom-returnproduct-modal {
  & .modal-dialog {
    @media (max-width: 767.98px) {
      // display: flex; 
    }
  }
  & .custom-body {
    @media (max-width: 767.98px) {
      width: 100% !important;
      height: 100%;
      border-radius: 24px 24px 0 0;

      .custom-content-container {
        height: calc(100% - 62px - 88px);
        padding: 0px 16px;
        
        .custom-content {
          border-top: 1px solid #EAECF0;
          padding: 10px 0px;
          height: 100%;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 3px;
            background-color: #e4e4e4 !important;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #b6b6b6;
            border-radius: 999px !important;
          }
          &::-webkit-scrollbar-thumb:hover {
            background-color: #b6b6b6;
            cursor: pointer;
          }
        }
      }

      .custom-footer-container {
        background-color: #E1EFFE;
        padding: 16px;
        border-radius: 16px 16px 0 0;
        height: 88px;
      }
    }
    @media (min-width: 767.98px) {
      width: var(--modalContainer) !important;

      .custom-content-container {
        // height: 100%;
        padding: 16px;
      }
    }
    height: 100%;
    background-color: #ffffff;
    display: inline-block;
    border-radius: 8px;
    overflow: hidden;

    & .payment-body {
      box-shadow: none !important;
    }
  }
  & .modal-content {
    @media (max-width: 767.98px) {
      border-radius: 24px 24px 0 0 !important;
      // height: 90vh !important;
      bottom: 0;
      align-self: flex-end;
    }
    @media (min-width: 767.98px) {
      background-color: transparent !important; 
      & .modal-body {
        padding: 20px 0px !important;
      }
    }
  }
}

.selectedItemProduct {
  width: 30px;
  height: 30px;
  background-color: #ecf7ff;
  color: #0080fc;
  border-radius: 50%;
  padding: 0 0px;

  margin-left: 6px;
  display: flex;
  align-items: center;
}

.product_return {
  border: 1px solid #eaecf0;
  border-radius: 10px;

  .product_mob_first {
    padding: 12px;
  }

  .product_mob_sec {
    background-color: #f9fafb;
    padding: 12px;
    border-radius: 0px 0px 10px 10px;

    .text-gray-return {
      color: #98a2b3;
    }
  }
}

.payment-head {
  padding: 20px;
}

.btn-payment-return {
  background-color: #0080fc;
  color: #ffff;
  padding: 10px 16px;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  font-weight: 600;
}

.btn-payment-return:hover {
  background-color: #006ad5;
}

.currency-input {
  padding-left: 20px;
  /* Make room for the $ symbol */
  position: relative;
}

.currency-input::before {
  content: '$';
  position: absolute;
  left: 5px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  pointer-events: none;
  color: #333;
}

.paymet-head-text {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.btn-payment-return-footer {
  background-color: #0080fc;
  color: #ffff;
  padding: 10px 16px 10px 16px;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  font-weight: 600;
  width: 120px;
}

.btn-payment-return-footer:hover {
  background-color: #006ad5;
}

.last-row {
  padding: 12px 24px !important;
  background-color: #f9fafb;
  width: 100% !important;
}

.last-row-tr {
  padding: 0px !important;
}

// ---------------
.product_name {
  font-size: 14px;
  font-weight: 500;
  margin-left: 12px;
}

.units_name {
  font-size: 14px;
  font-weight: 500;
}

// .modal_head {
//     background-color: #FFFFFF !important;
//     padding: 24px 16px 0px 16px !important;
// }

.payment-footer-mob-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1001;

  .payment-footer-mob-btn-modal {
    background-color: #e1effe;
    padding: 16px 16px 32px 16px;
    border-radius: 16px 16px 0 0;
    box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.1019607843);
  }
}

.modal_head_payment {
  // padding: 10px 10px;
  background-color: #f9fafb !important;
  padding: 20px 21px 20px 21px !important;
  border-radius: 0;
  // background: #f9fafb;
  border-bottom: 1px solid #eaecf0;

  .back-link {
    //   margin-right: 8px;
    //   color: $gray7;
    cursor: pointer;
    //   padding-bottom: 16px;
    border-bottom: none !important;
    font-weight: 500;
  }

  .modal-title {
    font-size: 20px;
    color: #667085;
  }

  .arrow_icon {
    margin-right: 12px;
  }
}

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

.due_amount_error {
  color: #dc6803;
  background-color: #feefc6;
  font-size: 14px;
  font-weight: 500;
}

.mobile-input-product-return:focus {
  box-shadow: none !important;
}

.product_return_mobile_modal {
  max-height: 70vh !important;
  height: auto;
}

.mobile-input-product-return::-webkit-outer-spin-button,
.mobile-input-product-return::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.mobile-input-product-return[type='number'] {
  -moz-appearance: textfield;
}

.cust-lable {
  display: inline-block;
  position: relative;
  min-width: 2em;
  min-height: 1.4em;
}

.tpl {
  white-space: pre;
  display: inline-block;
  padding: 4px 8px;
  padding-right: 0 !important;
  /* max-width : could be wised to set a maximum width and overflow:hidden; */
}

.test {
  position: absolute;
  vertical-align: top;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  padding-right: 0 !important;
}

/* ========media query================ */

@media (max-width: 1399.98px) {
}

@media (max-width: 1199.98px) {
}

@media (max-width: 1024px) {
}

@media (min-width: 991.98px) {
  .mobile-only-return {
    display: none !important;
  }

  .desktop-only-return {
    display: block !important;
  }
}

@media (min-width: 767.98px) {
  .payment-head {
    background-color: #fafcff;

    .title {
      font-size: 16px;
      font-weight: 500;
      color: #667085;
    }
  }

  .AddLink {
    font-size: 16px;
    font-weight: 500;
  }

  .paymet-head-text {
    font-size: 14px !important;
  }

  .modal_head_payment {
    // padding: 10px 10px;
    background-color: #f9fafb !important;
    padding: 20px 21px 20px 21px !important;
    border-radius: 0;
    // background: #f9fafb;
    border-bottom: 1px solid #eaecf0;

    .back-link {
      //   margin-right: 8px;
      //   color: $gray7;
      cursor: pointer;
      //   padding-bottom: 16px;
      border-bottom: none !important;
    }

    .modal-title {
      font-size: 20px;
      color: #667085;
    }

    .arrow_icon {
      margin-right: 12px;
    }
  }

  .payment-main-component {
    background: white;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #eaecf0;
    border-radius: 8px;
    box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.1019607843);
    border: 1px solid #e6e8ec;
    width: 600px;
    display: inline-block;
    overflow: hidden;
  }
}

@media (max-width: 767.98px) {
  .payment-head {
    padding: 16px 16px 16px 16px !important;
    background-color: #ffffff !important;
    border-bottom: none !important;
    border-radius: 24px 24px 0 0;

    .title {
      font-weight: 600;
      font-size: 16px;
      color: #1D2939;
    }
  }

  .modal_head_payment {
    padding: 24px 16px 0px 16px !important;
    border-radius: 0;
    background: #ffff !important;
    border-bottom: none !important;

    .back-link {
      //   margin-right: 8px;
      //   color: $gray7;
      cursor: pointer;
      padding-bottom: 16px !important;
      border-bottom: 1px solid #eaecf0 !important;
    }

    .modal-title {
      font-size: 20px;
    }
  }

  .modal_payment {
    .modal-dialog {
      margin: auto;
      position: fixed;
      bottom: 38px;
      left: 0;
      right: 0;
      width: 100%;
    }
  }

  .modal_container_mob {
    height: 90vh !important;
    overflow: scroll;
  }

  .return_upload_body {
    height: 68vh;

    .return_container {
      height: 100%;

      .image_return {
        height: 100% !important;
      }
    }
  }
  .payment-main-component {
    width: 100%;
  }
}

@media (max-width: 575.98px) {
}

@media (max-width: 425px) {
  .due_amount_error {
    font-size: 12px;
  }
}

.mobile-only-product {
  @media (max-width: 767.98px) {
    display: block !important;
  }
  @media (min-width: 767.98px) {
    display: none !important;
  }
}
.desktop-only-product {
  @media (max-width: 767.98px) {
    display: none !important;
  }
  @media (min-width: 767.98px) {
    display: block !important;
  }
}