.bgClass {
    /* color: var(--bs-dropdown-link-hover-color); */
    background-color: #c0d9ff !important;
  }

  .dropdown-item {
    white-space: normal !important;
  }

  .wspace {
    white-space: normal !important;
  }

  /* .dropDownPosition .custom-dropdown-menu.dropdown-menu.show {
    transform: translate(30rem, -28px) !important;
} */



.informationModalBorderRadius{
  @media (max-width: 768px) {
    border-radius: 24px 24px 0 0 !important;
  }
  @media (min-width: 768px) {
    border-radius: 7px !important;
  }
  border:1px solid white;
  background-color: white !important;
  /* border-bottom-right-radius: 7px !important; */
  /* border-bottom-left-radius: 7px !important; */
}

.generic-modal-footer23{
    border-bottom-right-radius: 7px !important;
    border-bottom-left-radius: 7px !important;
    /* border:1px solid white; */
    @media (max-width: 575px) {
      background-color: #e1effe !important;
    }
    @media (min-width: 575px) {
      background-color: #FAFAFC !important;
    }
    gap:10px;
    padding: 24px;
}


.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: white;
  border: 1px solid rgb(232, 234, 237);
  border-radius: 7px 0 0 7px !important;

  input {
    min-width: 85%;
  }
}

.react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 7px 0 0 7px !important;
}


@media only screen and (max-width : 900px) {
  .dropDownPosition .custom-dropdown-menu.dropdown-menu.show {
    width: 100%;
    transform: translate(0px, 36px) !important;
  }
}

@media (min-width : 900px) {
  .dropDownPosition .custom-dropdown-menu.dropdown-menu.show {
    width: 100%;
    transform: translate(0px, 36px) !important;
  }
}


.dropdown-toggle.too::after {
display: none !important;
}



@media only screen and (max-width: 575px) {

  .inputWidth{
    width: 100% !important ;
  }
}

.custom-btn-1 {
  border: solid 1px #EAECF0;
  border-radius: 8px;
  height: 42px;
  color: #475467;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  background-color: #ffffff;
  padding: 0;
  margin: 0;

  @media (max-width: 900px) {
    width: calc((100% - 16px) / 2);
  }
  @media (min-width: 900px) {
    width: 20% !important;
  }
}

.custom-btn-2 {
  border: solid 1px #0080FC;
  border-radius: 8px;
  height: 42px;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  background-color: #0080FC;
  padding: 0;
  margin: 0;

  @media (max-width: 900px) {
    width: calc((100% - 16px) / 2);
  }
  @media (min-width: 900px) {
    width: 35%;
  }
}

.information-container {
  max-height: 65vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0px;
    background-color: #e4e4e4 !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b6b6b6;
    border-radius: 999px !important;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #b6b6b6;
    cursor: pointer;
  }
}

.bg-footer {
  @media (max-width: 900px) {
    background-color: #e1effe !important;
  }
}

.information-container {
  @media (min-width: 900px) {
    overflow-y: visible !important;
  }
}

.rotate {
  transform: rotate(180deg);
}
