@import "@styles/variables";
@import '@styles/variables';
@import '@styles/mixins';

.btn-link {
  text-decoration: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.abc {
  box-shadow: none !important;
  border-color: #86b7fe !important;
}
.form-control:focus-visible {
  box-shadow: none !important;
  border-color: #86b7fe !important;
}

/* min-height: auto;
max-height: 550px;
padding: 10px;
display: flex;
overflow-x: scroll;
width: 500px; */

.roleBodyLeft {
  height: 576px;
  /* padding: 18px; */
  /* overflow-y: scroll; */
}


/* .roleBodyLeft::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.roleBodyLeft::-webkit-scrollbar
{
    width: 4px;
    background-color: #F5F5F5;
}

.roleBodyLeft::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}
 */


.heightscroll{
  height: 576px;
  padding: 10px;
}

.heightWeb{
  height: 707px;
}


/* .table-main {
  display: block;
  height: 535px;
  overflow-y: auto;
  overflow-x: hidden;
}
.table-main::-webkit-scrollbar {
  width: 3px;
  background-color: #e4e4e4 !important;
}
.table-main::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
  border-radius: 999px !important;
}
.table-main::-webkit-scrollbar-thumb:hover {
  background-color: #b6b6b6;
  cursor: pointer;
} */
 

.table thead {
  display: table;
  width: calc(100%); 
}

.table tbody {
  display: block;
  overflow-y: auto;
  height: 475px; 
  @media (max-width: 768px) {
    height: auto !important;
    overflow-y: auto;
  } 
  &.without-footer {
    height: 550px !important;
  }
}

.table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; 
  /* overflow-x: hidden; */
}
.table tbody::-webkit-scrollbar {
  width: 3px;
  background-color: #e4e4e4 !important;
}
.table tbody::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
  border-radius: 999px !important;
}
.table tbody::-webkit-scrollbar-thumb:hover {
  background-color: #b6b6b6;
  cursor: pointer;
}
 

.table-main2 {
  display: block;
  height: 621px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.table-main2::-webkit-scrollbar {
  width: 3px;
  background-color: #e4e4e4 !important;
}
.table-main2::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
  border-radius: 999px !important;
}
.table-main2::-webkit-scrollbar-thumb:hover {
  background-color: #b6b6b6;
  cursor: pointer;
}

 
/* Small Devices, Tablets */
@media only screen and (max-width: 575px) {
  .roleBodyLeft {
    /* width: 500px; */
    height: auto;
    overflow-x: scroll;
    display: flex;
    border-bottom: 1px solid rgba(0,0,0, 0.1);
  }
  .hideHead{
    display: none !important;
  }
.empty-td{
  width: 23px;
  padding: 0;
  border: none; /* Optionally remove border if not needed */
  overflow: hidden;
}

.adad{
  margin-bottom: 0.5rem !important;
  padding: 0.5rem 0rem !important;
}
.marginTOp{
  margin-top: 20px;
}

.heightWeb{
  height: unset;
}
}

@media only screen and (max-width: 900px) {
  .adad{
  margin-bottom: 0.5rem !important;
  padding: 0.5rem 0rem !important;
}
}

@media (max-width: 900px) {
  .table > :not(caption) > *:last-child > * {
    border-bottom-width: 0 !important;
  }
  .employee-create-batch {
    border-radius: 8px;
    @include flex-container(row, $justify: center, $align: center);
    gap: 5px;
    border: 0;
    background: $light-blue;
    // padding: 9px 16px;
    // min-width: 151px;
    font-weight: 600;
    > span {
      letter-spacing: 0;
      font-weight: 500;
      // width: 100px;
    }
    img {
      width: 16px;
      margin-right: 3px;
    }
    font: {
      size: 14px;
      weight: 600 !important;
    }
    color: white;
    .selected-count {
      @include flex-container(row, center, center);
      margin-right: 3px;
      background: $primary1;
      border-radius: 50%;
      color: $primary-color;
      min-width: 20px;
      min-height: 20px;
      padding: 3px;
      span {
        line-height: 0;
        display: block;
      }
    }
  }
}

.roles-item-container {
  @media (min-width: 900px) {
    width: calc(100% - 60px)
  }
}

.custom-fixed-header-list-mobile {
  @media (max-width: 575px) {
    position: fixed;
    width: calc(100vw - 58px);
    z-index: 999;
    border-radius: 14px 14px 0 0;
  }
}

tbody.custom-list-body-mobile {
  @media (max-width: 575px) {
    // height: 50vh !important;
    // overflow-y: auto;
    margin-top: 52px;
  }
}

.custom-header-role-mobile {
  @media (max-width: 575px) {
    position: fixed; 
    z-index: 999; 
    background-color: #fafafd
  }
}