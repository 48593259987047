$font-hankenGrotesk: 'Hanken Grotesk', sans-serif;
$font-inter: 'Inter', sans-serif;

// Default Theme Colors
$white-color: #fff;
$dark-white-color: #fff;
$black-color: #000;
$black-color2: #1e1f1f;
$black-color3: #0b0c0c;
$light-black-color: #242627;
$title-color: #0b0c0c;
$dark-title-color: #0b0c0c;
$title-color-opc: 11, 12, 12;
$text-color: #3f444b;
$text-color-opc: 63, 68, 75;
// Theme Colors
$primary-color1: #3067ff;
$primary-color1-opc: 48, 103, 255;
$primary-color2: #0b0c0c;
$dark-primary-color2: #0b0c0c;
$primary-color2-opc: 11, 12, 12;
$border-color: #eee;
$border-color2: #eee;

// Dark Theme Variables
$lp-dark-white-color: #0b0c0c !important;
$lp-dark-black-color: #000;
$lp-dark-black-color2: #1e1f1f;
$lp-dark-title-color: #fff;
$lp-dark-title-color-opc: 255, 255, 255;
$lp-dark-text-color: #b5b5b5;
$lp-dark-text-color-opc: 255, 255, 255;
$lp-dark-primary-color1: #3067ff;
$lp-dark-primary-color1-opc: 48, 103, 255;
$lp-dark-primary-color2: #fff;
$lp-dark-primary-color2-opc: 255, 255, 255;
$lp-dark-border-color: #5e5a5a6e;
$lp-dark-border-color2: #3d3a3a6e;

body.lp-dark {
  color: $lp-dark-text-color;
  background-color: $lp-dark-black-color;

  .style-2 {
    background-color: $lp-dark-black-color;
  }
}
