@import "@styles/variables";
@import "@styles/mixins";

.sidebar {
  width: 100%;
  min-height: 92.7vh;
  border-right: 1px solid #eaecf0;
  height: 100%;
  z-index: 100;
  position: relative;
  background: #f8fafe;
  padding: 24px 16px;
  .sidebar-link {
    align-items: center;
    gap: 10px;
    color: #767676;
  }
  .nav-pills {
    gap: 12px;
  }
  // .nav-link:hover img {
  //   filter: invert(17%) sepia(78%) saturate(2570%) hue-rotate(210deg) brightness(115%) contrast(100%);
  // }
  .nav-link {
    font-weight: 500;
    --bs-nav-link-padding-y: 16px;
    --bs-nav-link-padding-y: 10px;
    --bs-nav-pills-border-radius: 8px;
  }
  .audit-sidebar-btn {
    --bs-nav-link-padding-y: 10px !important;
  }
}

@include respond-to("tablet-landscape") {
  .sidebar {
    width: 250px;
    border: 0;
    .nav-link {
      --bs-nav-link-padding-y: 10px;
    }
  }
}

.svgMenu {
  gap: 13px;
}
@media (max-width: 768px) {
  .svgMenu {
    gap: 16.5px;
  }
}

.slideDownAnimation {
  animation: slideDown 0.5s ease forwards;
}

.slideUpAnimation {
  animation: slideUp 0.5s ease forwards;
}

@keyframes slideDown {
  0% {
      transform: translateY(-50%);
      opacity: 0;
  }
  100% {
      transform: translateY(0);
      opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50%);
    opacity: 0;
  }
}