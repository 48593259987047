@import "@styles/variables";
@import '@styles/variables';
@import '@styles/mixins';

.logs-list-component {
  ul {
    padding: 0;
  }

  @include flex-container(column);

  @media (min-width: 900px) {
    height: calc(100vh - 86px);
    width: calc(100vw - 230px - 40px) !important;

    @media (min-width: 1100px) {
      height: calc(100vh - 96px) !important;
      width: calc(100vw - 230px - 60px) !important;
    }
  }

  .nav-header {
    @include flex-container(row, $justify: space-between, $align: center);
    padding: 0px 20px;
    background: #f9fafb;
    border-bottom: 1px solid #e6e8ec;
    border-radius: 8px 8px 0 0;
  }

  .nav-links {
    @include flex-container(row, $justify: flex-start, $align: center);
    gap: 32px;

    .active {
      border-bottom: solid 2px $light-blue;

      .navbar-link {
        color: #0080fc !important;
      }
    }
    .navbar-link {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #767676;
      padding: 20px 4px;
      cursor: pointer;
      font: {
        weight: 500;
        size: 14px;
      }

      line-height: 20px;
    }
  }

  .log-right-bar {
    @include flex-container(row, $justify: flex-end, $align: stretch);

    padding: 0px;
    gap: 10px;
    flex-grow: 0;
    height: 36px;
    @media (max-width: 900px) {
      background-color: #fafafd;
      gap: 12px !important;
    }
  }
  .search-bar-input {
    background: white;
    @include flex-container(row, $justify: center, $align: center);
    gap: 12px;
    border: 1px solid $border-color;
    border-radius: 6px !important;
    padding: 8px;
    @media (min-width: 900px) {
      border-radius: 8px !important;
    }

    input {
      border: 0;
      min-width: 270px;
      padding: 0 !important;
      &:focus {
        outline: none;
      }
    }
  }
  .navbar {
    border: solid 1px $border-color;
    border-bottom: 1px solid #eaecf0;
    padding: 0px 20px 0px 20px;
    background: #e6e8ec;
    border-radius: 8px 8px 0px 0px;

    @media (min-width: 900px) {
      width: calc(100vw - 230px - 40px - 1px) !important;

      @media (min-width: 1100px) {
        width: calc(100vw - 230px - 60px - 1px) !important;
      }
    }
  }

  .logs-list-content { 
    padding: 16px 20px;
    width: 100%;
    overflow: hidden;
  }
  .log-filter-button {
    width: 42px;
    height: 42px;
    border-radius: 6px !important;
    @media (min-width: 900px) {
      width: 44px !important;
      height: 44px !important;
      border-radius: 8px !important;
    }
    @include flex-container(row, $justify: center, $align: center);
    gap: 0px;
    border: 0;
    background: $gray4;
    padding: 0px;
    font-weight: 600;

    > span {
      letter-spacing: 0;
      width: 88px;
    }

    img {
      width: 16px;
      margin-right: 3px;
    }
    font: {
      size: 14px;
      weight: 600 !important;
    }

    color: white;

    .selected-count {
      @include flex-container(row, center, center);
      margin-right: 3px;
      background: $primary1;
      border-radius: 50%;
      color: $primary-color;
      min-width: 20px;
      min-height: 20px;
      padding: 3px;
      span {
        line-height: 0;
        display: block;
      }
    }
  }
  .log-date-button {
    width: 42px;
    height: 42px;
    border-radius: 6px !important;
    @media (min-width: 900px) {
      width: 44px;
      height: 44px;
      border-radius: 8px !important;
    }
    @include flex-container(row, $justify: center, $align: center);
    gap: 0px;
    border: 0;
    background: $light-blue;
    padding: 0px;
    font-weight: 600;

    > span {
      letter-spacing: 0;
      width: 88px;
    }

    img {
      width: 16px;
      margin-right: 3px;
    }
    font: {
      size: 14px;
      weight: 600 !important;
    }

    color: white;
  }
  .custom-date {
    @media (max-width: 900px) {
      display: none;
    }
  }
  .mobile-only {
    @media (min-width: 900px) {
      display: none !important;
    }
  }
}

@include respond-to('tablet-landscape') {
  .logs-list-component {
    font-size: 12px;
    width: 100%;

    .log-right-bar {
      height: 42px;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .nav-header {
      @include flex-container(
        column-reverse,
        $justify: flex-start,
        $align: stretch
      );
      padding: 20px 0 0;
      top: 0;
      background: #f9fafb;
      margin: auto;
      border: 0;
      position: fixed;
      z-index: 9;
      width: calc(100% - 32px);
    }

    .nav-links {
      gap: 0;

      .navbar-link {
        padding-bottom: 16px;
        margin-top: 4px;
        font-size: 14px;
      }

      > a {
        flex: 1;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid $base-gray;
      }
    }
    .search-bar-input-2 {
      width: 100% !important;
      justify-content: flex-start;
      border-radius: 6px !important;
      padding: 12px;
      position: relative;
      display: flex;
      align-items: center;
      @media (min-width: 900px) {
        border-radius: 8px !important;
      }

      &,
      & > input {
        background: $gray4;
        font-size: 16px;
        width: 100%;
        padding: 8px;
        border-radius: 8px;
        outline: none !important;

        &::placeholder {
          font-size: 12px;
        }
      }

      > img {
        // opacity: 0;
        display: none;
      }
    }
    .button-action {
      width: auto;
      display: flex;
      gap: 12px !important;
      @media (min-width: 900px) {
        gap: 10px !important;
      }
    }
    .logs-list-content {
      padding-right: 0;
      padding-left: 0;
      padding-top: 20px;
      margin-top: 105px;
      @media (min-width: 900px) {
        padding-top: 24px !important;
      }
    }
    .drp_popover_mobile {
      width: 334px !important;
    }
  }
}
