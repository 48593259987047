.generic-modal-footer {
  padding: 24px !important;
  /* background-color: #f6f6f6 !important; */
  background-color: #fafafc;
  border-top: 0px !important;
  border-bottom-right-radius: 24px !important;
  border-bottom-left-radius: 24px !important;
  gap: 10px;
}

.user_image .w_100 {
  width: 100px !important;
}

.generic-modal-header {
  border-bottom: 1px solid #eaecf0;
}

.border_radius_8 {
  border-radius: 8px;
}

.generic_cancel_button {
  padding: 10px 16px;
  border: 1px solid #eaecf0;
  background-color: #fff;
  width: 88px !important;
  height: 42px !important;
  font-weight: 450;
}

.edit_cancel_button {
  padding: 10px 16px;
  border: 1px solid #eaecf0;
  background-color: #fff;
  font-size: 14px !important;
  font-weight: 450;
}

.generic_apply_button {
  padding: 10px 16px;
  font-size: 14px !important;
  font-weight: 450;
}

.delete_button {
  background-color: #0080fc;
  padding: 6px 20px !important;
  border: none;
  font-weight: 450;
}

.yes_button {
  background-color: #0080fc;
  padding: 10px 16px !important;
  border: none;
  width: 88px !important;
  height: 42px !important;
  font-weight: 450;
}

.no_button,
.yes_button {
  width: 80px;
}

.delete-modal img.rounded_image {
  border-radius: 100% !important;
}

.generic_modal_title {
  font-size: 20px;
}

.deactivate-modal img {
  border-radius: 100% !important;
  width: 100px;
}

.newLoaderAnimation {
  margin: 5px auto;
  display: block;
  animation: loading 1.2s linear infinite reverse;
}
.newLoaderAnimationSms {
    margin: 0 0;
  display: block;
  animation: loading 1.2s linear infinite reverse;
  svg {
    width: 20px;
    height: 20px;
  }
}

.addEmployeee {
  background: #e1effe;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 16px 16px 0px 0px;
}

.informationModalBorderRadius {
  @media (max-width: 768px) {
    border-radius: 24px 24px 0 0 !important;
  }
  @media (min-width: 768px) {
    border-radius: 7px !important;
  }
  border: 1px solid white;
  background-color: white !important;
}

/* Small Devices, Tablets */
@media only screen and (max-width: 575px) {
  .modal-dialog2 {
    margin: 0 !important;
    padding-top: 50px !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    padding-left: 0px !important;
  }

  .modal.show .modal-dialog {
    margin: 0 !important;
    padding: 0 15px;
  }
  .custom-modal .modal-dialog {
    padding: 0 !important;
  }

  .modal-content2 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .generic-modal-footer2 {
    justify-content: space-between !important;
    background-color: #e1effe;
  }
  .generic-modal-footer {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .generic-modal-footer3 {
    border-bottom-right-radius: 24px !important;
    border-bottom-left-radius: 24px !important;
  }
  .generic-modal-footer4 {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .generic_apply_button2 {
    width: 47% !important;
  }
  .edit_cancel_button2 {
    width: 47% !important;
  }
  .phoneInp {
    width: 100% !important;
  }
  .marginnnTOPP {
    margin-top: 15px;
  }

  .employee-detail-page .content {
    padding: 0px;
    margin-top: 38px !important;
  }
  .generic-modal-footer-padding {
    padding: 16px !important;
  }

.paddingPhone{
    padding: 24px 16px !important;
}
.generic-modal-footer3{
        border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.vehicle-detail-page .content {
    padding: 0px !important;
    margin-top: 30px;
}
.deleteFont{
    font-size: 16px !important;
}
.border__radius .modal-content {
    border-radius: 12px !important;
}

}

.custom_sidebar_link {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

/* a.nav-link.justify-content-between.d-flex.sidebar-link {
    padding-top: 10px;
    padding-bottom: 10px;
} */
