@import "@styles/variables";
@import '../styles/variable';

// Mixins Css
@mixin eighteen-down-device {
  @media (max-width: 1799px) {
    @content;
  }
}
@mixin seventeen-down-device {
  @media (max-width: 1699px) {
    @content;
  }
}

@mixin fifteen-down-device {
  @media (max-width: 1499px) {
    @content;
  }
}

@mixin xxl-down-device {
  @media (max-width: 1399px) {
    @content;
  }
}

@mixin xl-down-device {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin xxl-device {
  @media (min-width: 1400px) and (max-width: 1599px) {
    @content;
  }
}

@mixin xl-device {
  @media (min-width: 1200px) and (max-width: 1399px) {
    @content;
  }
}
@mixin lg-device {
  @media (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}

@mixin xl-up-device {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin lg-up-device {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin lg-down-device {
  @media (max-width: 991px) {
    @content;
  }
}

// md-device============
@mixin md-device {
  @media (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin xxl-up-device {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin md-up-device {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin md-down-device {
  @media (max-width: 767px) {
    @content;
  }
}

// sm-device
@mixin sm-device {
  @media (min-width: 576px) and (max-width: 768px) {
    @content;
  }
}

@mixin sm-down-device {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin sm-mobile-device {
  @media (max-width: 425px) {
    @content;
  }
}

@mixin big-mobile-device {
  @media (min-width: 375px) and (max-width: 576px) {
    @content;
  }
}

@mixin threefifty-down-device() {
  @media (max-width: 350px) {
    @content;
  }
}

.feedback-container {
  margin-top: 70px;
  margin-bottom: 0px;

  @include lg-down-device() {
    margin-bottom: 70px;
  }
  @include md-down-device() {
    margin-top: 50px;
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;

    h1 {
      color: rgba(7, 34, 109, 1);
      font-family: $font-hankenGrotesk;
      font-size: 50px;
      font-weight: 800;
      line-height: 1.2;
      margin-bottom: 0;
      text-align: center;
      @include md-down-device() {
        font-size: 40px;
      }
      @include sm-down-device() {
        font-size: 38px;
      }

      .br {
        display: none;
        @include md-down-device() {
          display: block;
        }
      }
    }
  }

  .mobile {
    display: none !important;
    @include md-down-device() {
      display: block !important;
    }
  }

  .desktop {
    display: block;
    @include sm-down-device() {
      display: none !important;
    }
  }

  .feedback-list-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: 50px 60px;

    @include xl-down-device() {
      padding: 50px 30px;
      gap: 25px;
    }
    @include lg-down-device() {
      padding: 50px 20px 30px 20px;
      gap: 20px;
    }
    @include sm-down-device() {
      flex-direction: column;
      padding: 50px 15px 10px 15px;
      gap: 15px;
    }

    .item {
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 1);
      padding: 30px;
      outline-style: none;
      outline-width: 0px;
      outline-color: transparent;
      // box-shadow: 0px 4px 8px rgba(107, 102, 102, 0.25);
      box-shadow: 0px 2px 6px rgba(107, 102, 102, 0.25);
      width: 100%;
      height: 100%;

      @include xl-down-device() {
        padding: 28px;
      }
      @include sm-down-device() {
        padding: 25px;
        outline-style: solid;
        outline-width: 1px;
      }

      .content {
        display: flex;
        flex-direction: column;
        text-align: left;

        @include md-down-device() {
          margin-top: 15px;
        }
        @include sm-down-device() {
          margin-top: 10px;
        }

        .title {
          color: rgba(11, 12, 12, 1);
          font-family: $font-hankenGrotesk;
          font-size: 20px;
          font-weight: 700;
          line-height: 1.5;
          margin-top: 0px;
          margin-bottom: 0;
          text-align: left;
          display: block;
        }

        .subtitle {
          color: rgba(48, 103, 255, 1);
          font-family: $font-inter;
          font-size: 12px;
          font-weight: 500;
          line-height: 1;
          margin-top: 1px;
          margin-bottom: 0;
          text-align: left;
          display: block;
        }

        .description {
          margin-top: 15px;
          color: rgba(7, 34, 109, 1);
          font-family: $font-inter;
          font-size: 18px;
          font-weight: 400;
          line-height: 1.8;
          margin-bottom: 0;
          text-align: left;
          display: block;

          @include md-down-device() {
            font-size: 15px;
          }
        }
      }
    }

    .swiper-controls {
      margin-top: 40px;
      padding: 0px 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: calc(100vw - 50px);

      @include sm-down-device() {
        width: calc(100vw - 30px);
      }

      p {
        margin: 0px;
        font-size: 16px;
        color: #000000;
        font-weight: 400;

        @include md-up-device() {
          font-family: $font-hankenGrotesk;
        }

        .current-page {
          font-size: 24px;
        }
      }

      .control-btn-container {
        display: flex;
        flex-direction: row;
        gap: 12px;

        .control-btn {
          background-color: #cae5ff;
          border-radius: 999px;
          width: 42px;
          height: 42px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border: none;
          outline: none;
          padding: 0px;

          svg {
            width: 20px;
            height: 20px;
          }

          &.prevBtn {
            transform: rotate(180deg);
          }

          &.disable {
            opacity: 0.45;
            cursor: auto;
          }
        }
      }
    }
  }

  .franctional-pagi2 {
    color: $black-color;
    font-family: $font-hankenGrotesk;
    font-size: 24px;
    font-weight: 400;

    // @include md-down-device() {
    //   font-size: 20px;
    // }
    @include sm-down-device() {
      top: unset;
      position: relative;
      right: unset;
      display: flex;
      line-height: normal;
    }
    .swiper-pagination-current {
      color: $black-color;
      font-family: $font-hankenGrotesk;
      font-size: 24px;
      font-weight: 400;

      @include sm-down-device() {
        font-size: 22px;
      }

      // @include md-down-device() {
      //   font-size: 20px;
      // }
    }
    .swiper-pagination-total {
      color: $black-color;
      font-family: $font-hankenGrotesk;
      font-size: 16px;
      font-weight: 400;

      // @include md-down-device() {
      //   font-size: 20px;
      // }
      @include sm-down-device() {
        padding-top: 2px;
        padding-left: 1px;
        font-size: 15px;
      }
    }
  }
}

// dark mode
body.lp-dark {
  .feedback-container {
    .title {
      h1 {
        color: $white-color;
      }
    }
    .feedback-list-container {
      .item {
        background-color: $light-black-color;

        .content {
          .title {
            color: $white-color;
          }
          .description {
            color: $lp-dark-text-color;
          }
        }
      }
    }
    .franctional-pagi2 {
      color: $white-color;
      span {
        color: $white-color;
      }
    }
  }
}
