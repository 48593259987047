@import "@styles/variables";
// custom variable
$white-color: #fff;
$dark-white-color: #0b0c0c;
$black-color: #000;
$black-color2: #1e1f1f;
$title-color: #fff;
$title-color-opc: 255, 255, 255;
$text-color: #b5b5b5;
$text-color-opc: 255, 255, 255;
$primary-color1: #3067ff;
$primary-color1-opc: 48, 103, 255;
$primary-color2: #fff;
$primary-color2-opc: 255, 255, 255;
$border-color: #5e5a5a6e;
$border-color2: #3d3a3a6e;
$font-hankenGrotesk: 'Hanken Grotesk', sans-serif;
$font-inter: 'Inter', sans-serif;

// Mixins Css
@mixin eighteen-down-device {
  @media (max-width: 1799px) {
    @content;
  }
}
@mixin seventeen-down-device {
  @media (max-width: 1699px) {
    @content;
  }
}

@mixin fifteen-down-device {
  @media (max-width: 1499px) {
    @content;
  }
}

@mixin xxl-down-device {
  @media (max-width: 1399px) {
    @content;
  }
}

@mixin xl-down-device {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin xxl-device {
  @media (min-width: 1400px) and (max-width: 1599px) {
    @content;
  }
}

@mixin xl-device {
  @media (min-width: 1200px) and (max-width: 1399px) {
    @content;
  }
}
@mixin lg-device {
  @media (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}

@mixin xl-up-device {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin lg-up-device {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin lg-down-device {
  @media (max-width: 991px) {
    @content;
  }
}

// md-device============
@mixin md-device {
  @media (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin xxl-up-device {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin md-up-device {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin md-down-device {
  @media (max-width: 767px) {
    @content;
  }
}

// sm-device
@mixin sm-device {
  @media (min-width: 576px) and (max-width: 768px) {
    @content;
  }
}

@mixin sm-down-device {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin sm-mobile-device {
  @media (max-width: 425px) {
    @content;
  }
}

@mixin big-mobile-device {
  @media (min-width: 375px) and (max-width: 576px) {
    @content;
  }
}

@mixin threefifty-down-device() {
  @media (max-width: 350px) {
    @content;
  }
}

.breadcrumb-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 200px 0px 90px;

  @include lg-down-device() {
    padding: 160px 0px 80px;
  }

  .banner-wrapper {
    .banner-content {
      .title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        h1 {
          color: $white-color;
          font-family: $font-hankenGrotesk;
          font-size: 70px;
          font-weight: 800;
          line-height: 1.2;
          margin-bottom: 0;
          text-align: center;
          @include md-down-device() {
            font-size: 50px;
          }
          @include sm-down-device() {
            font-size: 38px;
          }

          .br {
            display: none;
            @include md-down-device() {
              display: block;
            }
          }
        }
      }
      .subtitle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        p {
          margin: 25px 0 0;
          font-family: $font-inter;
          color: $white-color;
          opacity: 0.6;
          font-size: 22px;
          font-weight: 400;
          line-height: 1.3;
          text-align: center;
          width: 600px;
          @include md-down-device() {
            font-size: 20px;
            width: 400px;
          }
          @include sm-down-device() {
            font-size: 19px;
            width: 310px;
          }
        }
      }
      .breadcrumb-list {
        padding: 0;
        margin: 0;
        list-style: none;
        display: inline-flex;
        align-items: center;
        gap: 10px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 50px;
        padding: 11px 22px;
        line-height: 1;
        margin-bottom: 12px;
        li {
          color: $white-color;
          font-family: $font-inter;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.39px;
          text-transform: uppercase;
          position: relative;
          padding-left: 16px;
          a {
            color: $primary-color1;
          }
          &::before {
            content: '';
            width: 8px;
            height: 2px;
            border-radius: 50%;
            background-color: $white-color;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          &:first-child {
            padding-left: 0;
            &::before {
              display: none;
            }
          }
        }
      }
    }
    .scroll-down-btn {
      animation: up-down2 2s linear infinite alternate;
      display: flex;
      justify-content: center;
      padding-top: 75px;
      padding-bottom: 50px;
      @keyframes up-down2 {
        0% {
          transform: translateY(40px);
        }
        50% {
          transform: translateY(20px);
        }
        100% {
          transform: translateY(40px);
        }
      }
      @include md-down-device() {
        padding-top: 70px !important;
        padding-bottom: 40px;
      }
      a {
        width: 41px;
        height: 80px;
        border-radius: 110px;
        border: 1px solid rgba(#fff, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
        svg {
          fill: none;
          stroke: rgba(#fff, 0.5);
          transition: 0.5s;
        }
        &:hover {
          background-color: $primary-color1;
          border-color: $primary-color1;
          svg {
            stroke: $white-color;
          }
        }
      }
    }
  }
}
