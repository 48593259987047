@import "@styles/variables";
@import '@styles/variables';
@import '@styles/mixins';

.payments-list-component {
  mobile-only {
    display: none;
  }

  ul {
    padding: 0;
  }

  li {
    list-style: none;
  }

  @media (min-width: 900px) {
    height: calc(100vh - 86px);
    width: calc(100vw - 230px - 40px) !important;

    @media (min-width: 1100px) {
      height: calc(100vh - 96px) !important;
      width: calc(100vw - 230px - 60px) !important;
    }
  }

  @include flex-container(column);

  .nav-header {
    @include flex-container(row, $justify: space-between, $align: center);
    padding: 0px 20px;
    background: #f9fafb;
    border-bottom: 1px solid #e6e8ec;
    border-radius: 8px 8px 0 0;
    padding-block: 14px;
  }

  .nav-links {
    @include flex-container(row, $justify: flex-start, $align: center);
    gap: 32px;

    .active {
      border-bottom: solid 2px $light-blue;

      .navbar-link {
        color: #0080fc !important;
      }
    }

    .navbar-link {
      display: flex;
      align-items: center;
      color: #767676;
      padding: 20px 4px;
      cursor: pointer;

      font: {
        weight: 500;
        size: 14px;
      }

      line-height: 20px;
    }
  }

  .right-bar {
    @include flex-container(row, $justify: flex-end, $align: stretch);

    padding: 0px;
    gap: 12px;
    flex-grow: 0;
    height: 52px;
    align-items: center;

    @media (min-width: 900px) {
      gap: 10px !important;
      height: 44px !important;
    }

    .add-payment-button {
      font-size: 14px;
      @include flex-container(row, $justify: center, $align: center);
      border-radius: 8px;
      height: 36px;
      padding: 9px 16px;
      border: 0;
      background: $light-blue;
      font-weight: 600;
      gap: 5px;

      img {
        width: 16px;
        margin-right: 3px;
      }

      span {
        // color: white;
        letter-spacing: 0;
        // width: 88px;
      }

      font: {
        size: 14px;
        weight: 600 !important;
      }

      color: white;
    }

    .selected-count {
      @include flex-container(row, center, center);
      margin-right: 3px;
      background: $primary1;
      border-radius: 50%;
      color: $primary-color;
      min-width: 20px;
      min-height: 20px;
      padding: 3px;

      span {
        line-height: 0;
        display: block;
      }
    }
  }

  .search-bar-input {
    background: white;
    @include flex-container(row, $justify: center, $align: center);
    gap: 12px;
    border: 1px solid $border-color;
    border-radius: 6px !important;

    @media (min-width: 900px) {
      border-radius: 8px !important;
    }

    // padding: 0 10px;
    padding: 8px;

    input {
      border: 0;
      min-width: 270px;

      &:focus {
        outline: none;
      }
    }
  }

  .navbar {
    border: solid 1px $border-color;
    border-bottom: 1px solid #eaecf0;
    padding: 0px 20px 0px 20px;
    background: #e6e8ec;
    border-radius: 8px 8px 0px 0px;

    @media (min-width: 900px) {
      width: calc(100vw - 230px - 40px - 1px) !important;

      @media (min-width: 1100px) {
        width: calc(100vw - 230px - 60px - 1px) !important;
      }
    }
  }

  .payments-list-content {
    padding: 16px 20px;
    width: 100%;
    overflow: hidden;
    // overflow-x: auto;
  }

  .download_btn_wrap {
    .btn {
      padding: 0;

      &:hover {
        transition: 0.3s ease all;
        opacity: 0.85;
        scale: 0.95;
      }
    }
  }

  .create-batch {
    border-radius: 8px;
    @include flex-container(row, $justify: center, $align: center);
    gap: 5px;
    border: 0;
    background: $light-blue;
    padding: 9px 16px;
    // min-width: 151px;
    font-weight: 600;

    > span {
      letter-spacing: 0;
      width: 88px;
    }

    img {
      width: 16px;
      margin-right: 3px;
    }

    font: {
      size: 14px;
      weight: 600 !important;
    }

    color: white;

    .selected-count {
      @include flex-container(row, center, center);
      margin-right: 3px;
      background: $primary1;
      border-radius: 50%;
      color: $primary-color;
      min-width: 20px;
      min-height: 20px;
      padding: 3px;

      span {
        line-height: 0;
        display: block;
      }
    }
  }
}

@include respond-to('tablet-landscape') {
  .nav-links-payment {
    display: none !important;
  }

  .payments-list-component {
    font-size: 12px;

    .right-bar {
      height: 52px;
      & .btn-action {
        padding: 0px !important;
        border-radius: 6px !important;
        width: 52px;
        height: 52px;
        border: none;
      }
    }

    .desktop-only {
      display: none !important;
    }

    .nav-header {
      @include flex-container(
        column-reverse,
        $justify: flex-start,
        $align: stretch
      );
      padding: 20px 0 0;
      top: 0;
      background: #f9fafb;
      margin: auto;
      border: 0;
      position: fixed;
      z-index: 9;
      width: calc(100% - 32px);
    }

    .nav-links {
      gap: 0;

      .navbar-link {
        padding-bottom: 16px;
        margin-top: 4px;
        font-size: 12px;
      }

      > a {
        flex: 1;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid $base-gray;
      }
    }

    .search-bar-input {
      width: 100%;
      justify-content: flex-start;
      border-radius: 6px !important;
      padding: 12px;
      height: 52px !important;

      @media (min-width: 900px) {
        height: 44px !important;
        border-radius: 8px !important;
      }

      input {
        border: 0;

        &:focus {
          outline: none;
        }
      }

      &,
      & > input {
        background: $gray4;
        min-width: 180px;
        font-size: 12px;

        &::placeholder {
          font-size: 12px;
        }
      }

      > img {
        // opacity: 0;
        display: none;
      }
    }

    .create-batch {
      $bottom: 32px;
      position: fixed;
      bottom: $bottom;
      z-index: 10;
      left: 50%;
      transform: translateX(-50%);
      transform-style: preserve-3d;
      width: calc(100vw - 2 * 16px);
      border-radius: 8px;
      height: 36px;

      &::after {
        content: '';
        position: fixed;
        background: #e1effe;
        margin: 0 auto;
        width: 100vw;
        left: 50%;
        bottom: -$bottom;
        z-index: -1;
        transform: translateZ(-1px) translateX(-50%);
        height: 88px;
        border-radius: 16px 16px 0px 0px;
      }
    }

    .download_btn_wrap {
      display: none;
    }

    .payments-list-content {
      padding-right: 0;
      padding-left: 0;
      padding-top: 20px;
      margin-top: 50px;
      @media (min-width: 900px) {
        padding-top: 24px !important;
      }
    }
  }
}
