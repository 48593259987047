@import "@styles/variables";
@import '../styles/variable';

// Mixins Css
@mixin eighteen-down-device {
  @media (max-width: 1799px) {
    @content;
  }
}
@mixin seventeen-down-device {
  @media (max-width: 1699px) {
    @content;
  }
}

@mixin fifteen-down-device {
  @media (max-width: 1499px) {
    @content;
  }
}

@mixin xxl-down-device {
  @media (max-width: 1399px) {
    @content;
  }
}

@mixin xl-down-device {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin xxl-device {
  @media (min-width: 1400px) and (max-width: 1599px) {
    @content;
  }
}

@mixin xl-device {
  @media (min-width: 1200px) and (max-width: 1399px) {
    @content;
  }
}
@mixin lg-device {
  @media (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}

@mixin xl-up-device {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin lg-up-device {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin lg-down-device {
  @media (max-width: 991px) {
    @content;
  }
}

// md-device============
@mixin md-device {
  @media (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin xxl-up-device {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin md-up-device {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin md-down-device {
  @media (max-width: 767px) {
    @content;
  }
}

// sm-device
@mixin sm-device {
  @media (min-width: 576px) and (max-width: 768px) {
    @content;
  }
}

@mixin sm-down-device {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin sm-mobile-device {
  @media (max-width: 425px) {
    @content;
  }
}

@mixin big-mobile-device {
  @media (min-width: 375px) and (max-width: 576px) {
    @content;
  }
}

@mixin threefifty-down-device() {
  @media (max-width: 350px) {
    @content;
  }
}

.faqs-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../../../public/assets/image/home3-faq-section-bg.png'),
    linear-gradient(180deg, #efefff 0%, #efefff 100%);
  padding: 90px 110px;
  margin-top: 0px;

  @include xl-down-device() {
    padding: 80px 70px;
  }
  @include md-down-device() {
    padding: 70px 20px;
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;

    h1 {
      color: rgba(7, 34, 109, 1);
      font-family: $font-hankenGrotesk;
      font-size: 50px;
      font-weight: 800;
      line-height: 1.2;
      margin-bottom: 0;
      text-align: center;
      @include md-down-device() {
        font-size: 40px;
      }
      @include sm-down-device() {
        font-size: 38px;
      }

      .br {
        display: none;
        @include md-down-device() {
          display: block;
        }
      }
    }
  }

  .content-container {
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include md-down-device() {
      margin-top: 60px;
    }

    .content {
      width: calc((100% - 40px) / 2);
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 20px;

      @include md-down-device() {
        width: 100%;
      }

      &.mobile {
        display: none;
        @include md-down-device() {
          display: flex;
        }
      }

      &.desktop {
        display: flex;
        @include md-down-device() {
          display: none;
        }
      }

      .collapse-item {
        width: 100%;
        background-color: transparent;
        height: fit-content;

        .collapse-header {
          border-bottom: 1px solid rgba(11, 12, 12, 0.08);
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          padding-bottom: 15px;

          .collapse-title {
            border: none;
            border-radius: unset;
            box-shadow: none;
            color: #07226d;
            font-family: $font-hankenGrotesk;
            font-size: 20px;
            font-weight: 600;
            line-height: 1.4;
            padding: 0;
            width: 90%;

            @include md-down-device() {
              font-size: 16px;
            }
          }

          .collapse-icon {
            font-size: 22px;
            font-weight: 500;
          }
        }

        .collapse-content {
          transition: all 0.5s cubic-bezier(0, 1, 0, 1);
          overflow: hidden;
          max-height: 0;
          height: fit-content;

          &.show {
            height: auto;
            max-height: 999px;
            transition: all 0.5s cubic-bezier(1, 0, 1, 0);
          }

          p {
            font-weight: 400;
            font-size: 18px;
            color: #07226d;
            margin: 0;
            padding: 20px;

            @include md-down-device() {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

// dark mode
body.lp-dark {
  .faqs-container {
    background-color: transparent;
    background-image: unset;

    .title {
      h1 {
        color: $white-color;
      }
    }

    .content-container {
      .content .collapse-item {
        .collapse-header {
          .collapse-title {
            color: $white-color;
          }
          .collapse-icon {
            color: $white-color;
          }

          svg rect {
            fill: $white-color;
          }
        }

        .collapse-content p {
          color: #b5b5b5;
        }
      }
    }
  }
}
