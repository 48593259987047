@import "@styles/variables";
@import '../styles/variable';

// Mixins Css
@mixin eighteen-down-device {
  @media (max-width: 1799px) {
    @content;
  }
}
@mixin seventeen-down-device {
  @media (max-width: 1699px) {
    @content;
  }
}

@mixin fifteen-down-device {
  @media (max-width: 1499px) {
    @content;
  }
}

@mixin xxl-down-device {
  @media (max-width: 1399px) {
    @content;
  }
}

@mixin xl-down-device {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin xxl-device {
  @media (min-width: 1400px) and (max-width: 1599px) {
    @content;
  }
}

@mixin xl-device {
  @media (min-width: 1200px) and (max-width: 1399px) {
    @content;
  }
}
@mixin lg-device {
  @media (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}

@mixin xl-up-device {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin lg-up-device {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin lg-down-device {
  @media (max-width: 991px) {
    @content;
  }
}

// md-device============
@mixin md-device {
  @media (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin xxl-up-device {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin md-up-device {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin md-down-device {
  @media (max-width: 767px) {
    @content;
  }
}

// sm-device
@mixin sm-device {
  @media (min-width: 576px) and (max-width: 768px) {
    @content;
  }
}

@mixin sm-down-device {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin sm-mobile-device {
  @media (max-width: 425px) {
    @content;
  }
}

@mixin big-mobile-device {
  @media (min-width: 375px) and (max-width: 576px) {
    @content;
  }
}

@mixin threefifty-down-device() {
  @media (max-width: 350px) {
    @content;
  }
}

.contact-section {
  background-color: $light-black-color;
  padding: 90px 0;
  margin-top: 230px;

  @include lg-device() {
    padding: 80px 0;
  }
  @include lg-down-device() {
    padding: 70px 0;
    margin-top: 0;
  }
  @include md-down-device() {
    padding: 60px 0;
  }
  .contact-content {
    .contact-area {
      margin-bottom: 80px;
      padding-top: 80px;
      @include xxl-down-device() {
        padding-top: 50px;
        margin-bottom: 60px;
      }
      @include sm-down-device() {
        padding-top: 30px;
        margin-bottom: 40px;
      }
      .hotline-area {
        display: flex;
        align-items: center;
        gap: 20px;
        .icon {
          position: relative;
          svg {
            fill: #3067ff;
          }
          &::after {
            content: '';
            height: 35px;
            width: 1px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -10px;
            background-color: rgba(#fff, 0.15);
          }
        }
        .content {
          span {
            color: #cacaca;
            font-family: $font-inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 1;
            display: inline-block;
            margin-bottom: 7px;
          }
          h6 {
            margin-bottom: 0;
            a {
              color: $white-color;
              font-family: $font-hankenGrotesk;
              font-size: 20px;
              font-weight: 600;
              line-height: 1;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  .contact-form-wrap {
    padding-left: 70px;
    margin-top: -220px;
    @include xxl-down-device() {
      padding-left: 40px;
    }
    @include xl-down-device() {
      padding-left: 0;
    }
    @include lg-down-device() {
      margin-top: 0;
    }
    .contact-form-area {
      background-image: linear-gradient(
        180deg,
        #edf1fc 0%,
        rgba(201, 215, 255, 0.13) 100%
      );
      background-color: $dark-white-color;
      border-radius: 10px;
      padding: 50px 45px;
      @include xxl-down-device() {
        padding: 45px 30px;
      }
      @include sm-down-device() {
        padding: 30px 20px;
      }
      h3 {
        font-size: 25px;
        font-family: $font-hankenGrotesk;
        font-weight: 700;
        margin-bottom: 35px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(63, 68, 75, 0.1);
        color: $title-color;
        @include sm-down-device() {
          font-size: 22px;
        }
      }
    }
  }
  &.two {
    margin-top: 120px;
    @include lg-down-device() {
      margin-top: 0;
    }
  }
}

.form-inner {
  line-height: 1;
  label {
    color: $black-color;
    font-family: $font-hankenGrotesk;
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
    line-height: 1;
    margin-bottom: 12px;
    cursor: pointer;
  }
  input.contact-input {
    width: 100%;
    background: $white-color;
    color: $black-color;
    font-family: $font-inter;
    font-size: 13px;
    font-weight: 400;
    padding: 10px 20px;
    height: 50px;
    border: 1px solid $border-color;
    border-radius: 5px;
    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
    }
    &:focus {
      border-color: $primary-color1;
    }
  }
  textarea.contact-input {
    width: 100%;
    border-radius: 5px;
    background: #fff;
    color: $black-color;
    border: 1px solid #eee;
    outline: none;
    font-family: $font-inter;
    font-size: 13px;
    font-weight: 400;
    padding: 20px 25px;
    min-height: 150px;
    &:focus {
      border-color: $primary-color1;
    }
    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
    }
  }
}

.primary-btn2 {
  border: none;
  border-radius: 5px;
  background-color: $primary-color2;
  font-family: $font-inter;
  font-weight: 600;
  font-size: 16px;
  color: $dark-white-color;
  letter-spacing: 0.48px;
  line-height: 1;
  padding: 16px 20px;
  position: relative;
  align-items: center;
  display: inline-flex;
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
  @include sm-down-device() {
    font-size: 15px;
  }
  > span {
    transition: opacity 0.2s, transform 0.2s;
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 102% 102%;
    border-radius: inherit;
    transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    opacity: 0;
    z-index: -1;
  }

  &::after {
    content: attr(data-text);
    position: absolute;
    top: 50%;
    opacity: 0;
    transform: translate(0, 100%);
    transition: opacity 0.2s, transform 0.2s;
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    white-space: nowrap;
  }

  &:hover {
    color: $white-color;
    background-color: #3067ff;

    &::before {
      opacity: 1;
    }

    > span {
      transform: translateY(-150%);
      opacity: 0;
    }

    &::after {
      transform: translate(0%, -50%);
      opacity: 1;
    }
  }
}

.section-title {
  > span {
    color: $primary-color1;
    font-family: $font-inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.36px;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 10px;
    @include md-down-device() {
      margin-bottom: 10px;
    }
    svg {
      fill: rgba(255, 255, 255, 0.3);
    }
  }
  h2 {
    color: $title-color;
    font-family: $font-hankenGrotesk;
    font-size: 50px;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 0;
    @include xl-down-device() {
      font-size: 38px;
    }
    @include sm-down-device() {
      font-size: 32px;
    }
  }
  p {
    color: $text-color;
    font-family: $font-inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.8;
    padding-top: 20px;
    margin-bottom: 0;
    @include lg-device() {
      font-size: 16px;
    }
    @include md-down-device() {
      padding-top: 16px;
    }
  }
  &.white {
    span {
      svg {
        fill: rgba(#fff, 0.5);
      }
    }
    h2 {
      color: $white-color;
    }
    p {
      color: rgba(#fff, 0.6);
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-loading-spinner-container {
  width: 94.44px;
  height: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @include md-down-device() {
    width: 88.66px;
    height: 15px;
  }

  .custom-loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
}

// dark mode
body.lp-dark {
  .contact-section {
    .contact-form-wrap .contact-form-area {
      background-color: $lp-dark-white-color;
      background-image: unset;

      h3 {
        color: $lp-dark-title-color;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }
  .form-inner {
    label {
      color: $lp-dark-title-color;
    }
    input.contact-input {
      background: $light-black-color;
      color: $white-color;
      border: unset;
      outline: none;
    }
    textarea.contact-input {
      background: $light-black-color;
      color: $white-color;
      border: unset;
      outline: none;
    }
  }
  .primary-btn2 {
    background-color: $white-color;
    color: $black-color;

    &:hover {
      color: $white-color;
      background-color: #3067ff;
    }
  }
}
