$primary-color: #3498db;
$primary1: #ecf7ff;
$primary2: #99d5ff;
$primary5: #0080fc;
$base-gray: #f6f6f6;
$gray: #d0d5dd;
$gray2: #f9fafb;
$gray3: #f2f4f7;
$gray4: #eaecf0;
$gray6: #98a2b3;
$gray7: #667085;
$gray8: #475467;
$gray9: #1d2939;
$gray300: #d0d5dd;
$secondary-color: #2ecc71;
$dark-blue: #0c2536;
$light-blue: #0d6efd;
$border-color: #e6e8ec;
$stock1: #eaecf0;
$base-black: #210d4a;
$error500: #f04438;
$success8: #039855;
$yellow: #FDB022;
$green: #12B76A;
