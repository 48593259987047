@import "@styles/variables";
@import '@styles/variables';
@import '@styles/mixins';
@import 'bootstrap/dist/css/bootstrap.min.css';

.drp_popover.custom-current-date .rdrDay.rdrDayToday .rdrDayNumber {
  background-color: transparent !important;
}
.drp_popover.custom-addpayment-date {
  max-width: 300px;
  .rdrCalendarWrapper  {
    padding: 15px;
  }
  .rdrWeekDay {
    color: #0080FC;
  }
  .rdrDayNumber span {
    color: #344054
  }
  // .rdrDayPassive > .rdrDayNumber span {
  //   color: #667085 !important;
  //   font-weight: 400 !important;
  // }
}

.image-upload-no-padding .modal-dialog {
  padding: 0 !important;
}

.custom-add-payment-modal.bg-transparent {
  .modal-content {
    background-color: transparent !important;
  } 
}

.custom-payment-input .payment-input {
  padding: 9px 20px !important;
}

.payment-table-component {
  outline: 1px solid $stock1;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;

  @media (min-width: 900px) {
    --rowWidth: calc(100vw - 230px - 40px - 40px);
    height: calc(100vh - 84px - 62.4px - 16px) !important;
    @media (min-width: 1100px) {
      --rowWidth: calc(100vw - 230px - 40px - 60px) !important;
      height: calc(100vh - 94px - 62.4px - 16px) !important;
    }
    position: relative;
    table-layout: fixed;
    width: var(--rowWidth) !important;
    overflow-x: hidden;

    thead {
      display: table-header-group;
      background-color: #f9fafb;
      tr {
        display: table-row;
        position: relative;

        th {
          padding: 0px 10px !important;
          height: 54px !important;

          &:first-child {
            @media (min-width: 1200px) {
              padding-left: 20px !important;
            }
          }
          &:last-child {
            @media (min-width: 1200px) {
              padding-right: 20px !important;
            }
          }

          &.id {
            min-width: 70px !important;
            max-width: 70px !important;
            width: 70px !important;
          }
          &.paymentmethod {
            min-width: 90px !important;
            max-width: 90px !important;
            width: 90px !important;
          }
          &.amount {
            min-width: 8% !important;
            max-width: 8% !important;
            width: 8% !important;
            padding: 20px 0px 20px 10px !important;
          }
          &.action,
          &.invoiceno,
          &.date,
          &.collectedby {
            min-width: calc(((var(--rowWidth) * 60%) - 160px) / 4) !important;
            max-width: calc(((var(--rowWidth) * 60%) - 160px) / 4) !important;
            width: calc(((var(--rowWidth) * 60%) - 160px) / 4) !important;
          }
          &.buyer {
            min-width: 15% !important;
            max-width: 15% !important;
            width: 15% !important;
          }
          &.companyname {
            min-width: 17% !important;
            max-width: 17% !important;
            width: 17% !important;
          }
        }
      }
    }
    tbody {
      display: block;
      height: 100%;
      width: var(--rowWidth) !important;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 3px;
        background-color: #e4e4e4 !important;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #b6b6b6;
        border-radius: 999px !important;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #b6b6b6;
        cursor: pointer;
      }

      tr {
        display: table;
        table-layout: fixed;
        width: var(--rowWidth);
      }

      td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 20px 10px !important;

        &:first-child {
          @media (min-width: 1200px) {
            padding-left: 20px !important;
          }
        }
        &:last-child {
          @media (min-width: 1200px) {
            padding-right: 20px !important;
          }
        }

        &.id {
          min-width: 70px !important;
          max-width: 70px !important;
          width: 70px !important;
          overflow: visible;
          padding-left: 20px !important;
        }
        &.paymentmethod {
          min-width: 90px !important;
          max-width: 90px !important;
          width: 90px !important;
        }
        &.amount {
          min-width: 8% !important;
          max-width: 8% !important;
          width: 8% !important;
          padding: 20px 0px 20px 10px !important;
        }
        &.action,
        &.invoiceno,
        &.date,
        &.collectedby {
          min-width: calc(
            ((var(--rowWidth) * (60 / 100)) - 160px) / 4
          ) !important;
          max-width: calc(
            ((var(--rowWidth) * (60 / 100)) - 160px) / 4
          ) !important;
          width: calc(((var(--rowWidth) * (60 / 100)) - 160px) / 4) !important;
        }
        &.buyer {
          min-width: calc(var(--rowWidth) * (15 / 100)) !important;
          max-width: calc(var(--rowWidth) * (15 / 100)) !important;
          width: calc(var(--rowWidth) * (15 / 100)) !important;
        }
        &.companyname {
          min-width: 17% !important;
          max-width: 17% !important;
          width: 17% !important;
        }
      }
    }
  }

  input[type='checkbox'] {
    @include square(20px);
  }
  .view-detail {
    display: none;
    font: {
      size: 12px;
      weight: 500;
    }
  }
  tr:not(:last-child),
  thead {
    @media (max-width: 900px) {
      border: 1px solid $stock1;
    }
    @media (min-width: 900px) {
      border-bottom: 1px solid $stock1;
    }
  }
  th {
    // display: flex;
    color: $gray7;
    //text-transform: capitalize;
    background-color: $gray2;
    padding: 12px 24px 12px 24px;
    > span {
      @include flex-container($align: center);
      gap: 5px;
      font: {
        weight: 500;
        size: 12px;
      }
      > svg {
        @include square(16px);
      }
    }
  }
  .loading-row {
    width: 100%;
    td {
      width: 80vw;
    }
    svg {
      margin: 10px auto;
      display: block;
      animation: loading 1.2s linear infinite reverse;
    }
  }
  // Style loading svg
  tr {
    // position: relative;
    // z-index: 100;
    .payment-no {
      a {
        text-decoration: none;
      }
    }
    .payments_btns {
      display: flex;
      align-items: center;
      justify-content: start;
      column-gap: 20px;
    }

    &.active {
      background: #e8f2fd;
      border-color: $primary2;
    }
    td {
      color: $base-black;
      font-size: 14px;
      padding: 0 {
        left: 24px;
        right: 2px;
      }
      height: 80px;
    }
    .modal-header {
      border-bottom: 0px !important;
    }
    .modal-footer {
      border-top: 0px !important;
    }
    .custom-modal {
      .modal-content {
        border-radius: 12px !important;
      }
      .modal-header {
        .modal-title {
          display: flex !important;
          flex-direction: column !important;
          justify-content: center !important;
          align-items: center !important;
          text-align: center !important;
        }
      }
    }
    .check {
      padding: 0;
      // position: relative;
      pointer-events: all;
      label {
        cursor: pointer;
        height: 100%;
        width: 100%;
        // position: absolute;
        top: 0;
        left: 0;
        padding: 26px 0 {
          left: 24px;
        }
      }
    }
    .link-wrapper {
      cursor: pointer;
      display: contents;
    }
    .link-wrapper.mobile-only.payment-row {
      display: none;
    }
  }
  th {
    position: relative;
    // &:not(:first-of-type) {
    // min-width: 112px;
    // }
    &.open {
      &::before {
        content: '';
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: transparent;
        left: 0;
        top: 0;
        z-index: 101;
      }
      .sorting-options {
        display: block;
      }
    }
    max-width: 16.67%;
    > span:not(.unsorted) {
      cursor: pointer;
    }
    .sorting-options {
      display: none;
      background: white;
      top: 36px;
      left: 8px;
      background: white;
      padding: 4px 0;
      border-radius: 6px;
      box-shadow: 4px 4px 16px 0px #00000014;
      border: 1px solid #f6f6f6;
      transition: 0.8s;
      position: absolute;
      z-index: 101;
      div {
        padding: 10px;
        cursor: pointer;
        svg:last-child {
          opacity: 0;
        }
        &.selected {
          background: #f5f6f7;
          svg:last-child {
            opacity: 1;
          }
        }
        svg:first-child {
          margin-right: 27px;
        }
      }
    }
  }
}

.custom-add-payment-modal {
  & .payment-main-component {
    width: 550px !important;

    & .payment-body {
      box-shadow: none !important;
    }
  }
  & .modal-content {
    @media (max-width: 900px) {
      border-radius: 0 !important; 
    }
    @media (min-width: 900px) {
      background-color: transparent; 
    }
  }
  & .payment-head {
    @media (max-width: 767.98px) {
      padding: 0px 0px 12px 0px !important;
    }
    @media (min-width: 767.98px) {
      padding: 12px 24px !important;
    }
  }
}

@include respond-to('tablet-landscape') {
  .custom-modal.payment-modal .modal-dialog {
    padding: 0 15px !important;
  }

  .payment-table-component {
    outline: 0;
    thead {
      display: none;
    }
    .payment-no {
      display: none !important;
    }
    .link-wrapper.desktop-only {
      display: none;
    }
    .modal {
      width: 30%;
    }
    .link-wrapper.mobile-only.payment-row {
      width: 100%;
      display: block !important;
      @include flex-container($direction: column);
      .buyer-name {
        color: #0080fc;
        font-size: 12px;
        font-weight: 500;
        line-height: 17px;
      }
      .buyer-name2 {
        color: #210d4a;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 10px;
      }
      .payment-amount {
        font-size: 12px;
        color: #210d4a;
        span {
          color: #475467;
        }
      }
      .title-area {
        @include flex-container($justify: space-between, $align: center);
        .info {
          @include flex-container($align: center);
          row-gap: 10px;
          column-gap: 10px;
          a {
            text-decoration: none;
          }
        }
        .btns {
          .payments_btns {
            column-gap: 10px !important;
            span {
              width: 32px;
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 100%;
              svg {
                width: 18px;
                height: 18px;
              }
            }
            .edit-btn {
              background-color: $primary1;
            }
            .delete-btn {
              background-color: #fef3f2;
            }
          }
        }
      }
      .payment-dcc {
        @include flex-container($justify: space-evenly, $align: center);
        border-top: 0.1em solid $gray4;
        padding-top: 4px;
        hr {
          height: 33px;
          width: 1px;
          border-radius: 100%;
          background-color: $gray6;
          margin-top: 0.5rem;
        }
        p {
          @include flex-container(
            $direction: column,
            $align: center,
            $justify: center
          );
          margin-bottom: 0 !important;
          .block-title {
            color: #667085;
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
          }
          .block-value {
            color: #210d4a;
            font-weight: 500;
            line-height: 17px;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 99px;
            text-align: center;
            margin-top: 3px;
          }
        }
      }
    }
    tr {
      font-size: 12px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 12px;
      border: 1px solid $stock1;
      border-radius: 8px;
      margin-bottom: 16px;
      padding-bottom: 0;
      // pointer-events: none;
      &.active {
        border-color: $primary2;
        background: #e8f2fd;
        .check > input {
          border-color: $primary5;
        }
        td {
          border-color: white;
          border-right-color: white !important;
        }
      }
      &.no-checkbox {
        .amount {
          font-weight: 500;
        }
        .payment-no {
          width: 100%;
        }
        .amount {
          // padding-bottom: 27px;
        }
      }
      .onClick {
        display: block;
      }
      %row {
        // padding-bottom: 6.5px;
      }
      %first-row {
        @extend %row;
        line-height: 16.8px;
        margin-bottom: 6px;
        font-weight: 500;
      }
      input[type='checkbox'] {
        @include square(16px);
        border: 1px solid $gray300;
      }
      .check {
        @extend %first-row;
        order: 1;
        width: 28px;
        height: 17px;
        label {
          padding: 0;
        }
      }
      .payment-no {
        @extend %first-row;
        order: 2;
        width: calc(100% - 28px);
        height: 17px;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        .view-detail {
          display: unset;
          pointer-events: all;
        }
      }
      %second-row {
        @extend %row;
        border-bottom: 1px solid $stock1;
        margin-bottom: 7.5px;
        padding-bottom: 7.5px;
        // height: 28px;
      }
      .buyer {
        @extend %second-row;
        width: 70%;
        order: 3;
        font-size: 14px;
        line-height: 19.6px;
      }
      .amount {
        @extend %second-row;
        width: 30%;
        order: 4;
        font: {
          size: 12px;
          weight: 400;
        }
        line-height: 16.8px;
        @include flex-container(column, center, flex-end);
      }
      .date {
        order: 9;
      }

      $other-cols: company, date, overdue, due;
      @each $col in $other-cols {
        .#{$col} {
          @include flex-container(column, flex-start, center);
          gap: 2px;
          flex: 1;
          text-align: center;
          line-height: 16.8px;
          font: {
            weight: 500;
            size: 12px;
          }
          &::before {
            content: '#{$col}';
            text-transform: capitalize;
            color: $gray7;
            font-weight: 400;
          }
        }
      }
      .overdue {
        order: 10;
        &::before {
          content: 'Overdue By' !important;
        }
      }

      .other:not(.last) {
        border-right: 1px solid $stock1;
        padding-right: 3px;
      }
      // .company {
      //   @include flex-container(column);
      //   &::before {
      //     content: "Compnay";
      //   }
      // }
      td {
        order: 5;
        padding: 0;
      }
    }
  }
}

@keyframes loading {
  100% {
    transform: rotate(360deg);
  }
}

.modal-header {
  padding: 24px 0px 0px 0px;
  border-radius: 12px 12px 0px 0px;
  border-bottom: 0;
}

.btn-payment-cancel {
  background-color: #ffffff;
  padding: 9px 30px !important;
  color: #667085;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  border: solid 1px #D0D5DD;
}
