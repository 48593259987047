@import "@styles/variables";
@import '@styles/variables';
@import '@styles/mixins';

.employees-list-component {
  position: relative;
  width: 100%;
  ul {
    padding: 0;
  }
  @include flex-container(column);
  .nav-header {
    @include flex-container(row, $justify: space-between, $align: center);
    padding: 0px 20px;
    background: #f9fafb;
    border-bottom: 1px solid #e6e8ec;
    border-radius: 8px 8px 0 0;
  }
  .nav-links-sms {
    @include flex-container(row, $justify: flex-start, $align: center);
    gap: 3px;
    .active {
      border-bottom: solid 2px $light-blue;
      .navbar-link {
        color: #0080fc !important;
      }
    }
    .navbar-link {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #767676;
      padding: 20px 4px;
      cursor: pointer;
      font: {
        weight: 500;
        size: 14px;
      }
      line-height: 20px;
    }
  }
  .nav-links {
    @include flex-container(row, $justify: flex-start, $align: center);
    gap: 32px;
    .active {
      border-bottom: solid 2px $light-blue;
      .navbar-link {
        color: #0080fc !important;
      }
    }
    .navbar-lin-api {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #767676;
      padding: 20px 4px;
      cursor: pointer;
      font: {
        weight: 500;
        size: 16px;
      }
      line-height: 20px;
    }
    .navbar-link {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #767676;
      padding: 20px 4px;
      cursor: pointer;
      font: {
        weight: 500;
        size: 14px;
      }
      line-height: 20px;
    }
  }
  .employee-right-bar {
    @include flex-container(row, $justify: flex-end, $align: stretch);

    padding: 0px;
    gap: 16px;
    flex-grow: 0;
    height: 36px;
  }
  .search-bar-input {
    background: white;
    @include flex-container(row, $justify: center, $align: center);
    gap: 12px;
    border: 1px solid $border-color;
    border-radius: 8px;
    padding: 8px {
      right: unset;
    }
    input {
      border: 0;
      min-width: 270px;
      padding: 0 !important;
      &:focus {
        outline: none;
      }
    }
  }
  .navbar {
    border: solid 1px $border-color;
    border-bottom: 1px solid #eaecf0;
    padding: 0px 20px 0px 20px;
    background: #e6e8ec;
    border-radius: 8px 8px 0px 0px;
  }

  .employee-create-batch {
    border-radius: 8px;
    @include flex-container(row, $justify: center, $align: center);
    gap: 5px;
    border: 0;
    background: $light-blue;
    padding: 9px 16px;
    // min-width: 151px;
    font-weight: 600;
    > span {
      letter-spacing: 0;
      // width: 100px;
    }
    img {
      width: 16px;
      margin-right: 3px;
    }
    font: {
      size: 14px;
      weight: 600 !important;
    }
    color: white;
    .selected-count {
      @include flex-container(row, center, center);
      margin-right: 3px;
      background: $primary1;
      border-radius: 50%;
      color: $primary-color;
      min-width: 20px;
      min-height: 20px;
      padding: 3px;
      span {
        line-height: 0;
        display: block;
      }
    }
  }
}
.sms-list-component {
  border: 1px solid #E6E8EC;
  border-radius: 8px;
  position: relative;
  width: 100%;
  ul {
    padding: 0;
  }
  @include flex-container(column);
  .nav-header {
    @include flex-container(row, $justify: space-between, $align: center);
    padding: 0px 20px;
    background: #F2F4F7;
    border-bottom: 1px solid #e6e8ec;
    border-radius: 8px 8px 0 0;
  }
  .nav-links-sms {
    @include flex-container(row, $justify: flex-start, $align: center);
    gap: 5px;
    .active {
      border-bottom: solid 2px $light-blue;
      .navbar-link {
        color: #0080fc !important;
      }
    }
    .navbar-link {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #767676;
      padding: 15.5px 4px;
      cursor: pointer;
      font: {
        weight: 500;
        size: 14px;
      }
      line-height: 20px;
    }
  }
  .nav-links {
    @include flex-container(row, $justify: flex-start, $align: center);
    gap: 32px;
    .active {
      border-bottom: solid 2px $light-blue;
      .navbar-link {
        color: #0080fc !important;
      }
    }
    .navbar-link {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #767676;
      padding: 20px 4px;
      cursor: pointer;
      font: {
        weight: 500;
        size: 16px;
      }
      line-height: 20px;
    }
  }
  .employee-right-bar {
    @include flex-container(row, $justify: flex-end, $align: stretch);

    padding: 0px;
    gap: 16px;
    flex-grow: 0;
    height: 36px;
  }
  .search-bar-input {
    background: white;
    @include flex-container(row, $justify: center, $align: center);
    gap: 12px;
    border: 1px solid $border-color;
    border-radius: 8px;
    padding: 8px {
      right: unset;
    }
    input {
      border: 0;
      min-width: 270px;
      // padding: 0 !important;
      &:focus {
        outline: none;
      }
    }
  }
  .navbar {
    border: solid 1px $border-color;
    border-bottom: 1px solid #eaecf0;
    padding: 0px 20px 0px 20px;
    background: #e6e8ec;
    border-radius: 8px 8px 0px 0px;
  }

  .employee-create-batch {
    border-radius: 8px;
    @include flex-container(row, $justify: center, $align: center);
    gap: 5px;
    border: 0;
    background: $light-blue;
    // padding: 9px 16px;
    // min-width: 151px;
    font-weight: 600;
    > span {
      letter-spacing: 0;
      font-weight: 500;
      // width: 100px;
    }
    img {
      width: 16px;
      margin-right: 3px;
    }
    font: {
      size: 14px;
      weight: 600 !important;
    }
    color: white;
    .selected-count {
      @include flex-container(row, center, center);
      margin-right: 3px;
      background: $primary1;
      border-radius: 50%;
      color: $primary-color;
      min-width: 20px;
      min-height: 20px;
      padding: 3px;
      span {
        line-height: 0;
        display: block;
      }
    }
  }
}
@include respond-to('tablet-landscape') {
  .employees-list-component {
    font-size: 12px;
    .invoice-right-bar {
      height: 44px;
    }
    .invoice-right-bar-api {
      margin-bottom: 22px;
    }
    .nav-header {
      @include flex-container(
        column-reverse,
        $justify: flex-start,
        $align: stretch
      );
      padding: 20px 0 0;
      top: 0;
      background: #f9fafb;
      margin: auto;
      border: 0;
      position: fixed;
      z-index: 9;
      width: calc(100% - 32px);
    }
    .nav-links {
      gap: 0;
      display: none !important;
      .navbar-link {
        padding-bottom: 16px;
        margin-top: 4px;
        font-size: 12px;
      }
      > a {
        flex: 1;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid $base-gray;
      }
    }
    .invoice-create-batch {
      $bottom: 32px;
      position: fixed;
      bottom: $bottom;
      z-index: 10;
      left: 50%;
      transform: translateX(-50%);
      transform-style: preserve-3d;
      width: calc(100vw - 2 * 16px);
      max-width: 400px;
      border-radius: 4px;
      height: 36px;
      &::after {
        content: '';
        position: fixed;
        background: #e1effe;
        margin: 0 auto;
        width: 100vw;
        left: 50%;
        bottom: -$bottom;
        z-index: -1;
        transform: translateZ(-1px) translateX(-50%);
        height: 88px;
        border-radius: 16px 16px 0px 0px;
      }
    }
    .search-bar-input {
      width: 100%;
      justify-content: flex-start;
      border-radius: 4px; 
      padding: 12px {
        right: unset;
      }
      &,
      & > input {
        background: $gray4;
        font-size: 16px;
        width: 100%;
        &::placeholder {
          font-size: 12px !important;
        }
      }
      > img {
        // opacity: 0;
        display: none;
      }
    }
    .employees-list-content {
      padding-right: 0;
      padding-left: 0;
      padding-top: 24px;
    }
  }

  @media (max-width: 1024px) {
    .employees-list-component .employee-create-batch {
      width: 226px; // Full width on mobile devices
    }
  }
}
