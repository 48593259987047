@import "@styles/variables";
@import '../styles/variable';

// Mixins Css
@mixin eighteen-down-device {
  @media (max-width: 1799px) {
    @content;
  }
}
@mixin seventeen-down-device {
  @media (max-width: 1699px) {
    @content;
  }
}

@mixin fifteen-down-device {
  @media (max-width: 1499px) {
    @content;
  }
}

@mixin xxl-down-device {
  @media (max-width: 1399px) {
    @content;
  }
}

@mixin xl-down-device {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin xxl-device {
  @media (min-width: 1400px) and (max-width: 1599px) {
    @content;
  }
}

@mixin xl-device {
  @media (min-width: 1200px) and (max-width: 1399px) {
    @content;
  }
}
@mixin lg-device {
  @media (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}

@mixin xl-up-device {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin lg-up-device {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin lg-down-device {
  @media (max-width: 991px) {
    @content;
  }
}

// md-device============
@mixin md-device {
  @media (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin xxl-up-device {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin md-up-device {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin md-down-device {
  @media (max-width: 767px) {
    @content;
  }
}

// sm-device
@mixin sm-device {
  @media (min-width: 576px) and (max-width: 768px) {
    @content;
  }
}

@mixin sm-down-device {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin sm-mobile-device {
  @media (max-width: 425px) {
    @content;
  }
}

@mixin big-mobile-device {
  @media (min-width: 375px) and (max-width: 576px) {
    @content;
  }
}

@mixin threefifty-down-device() {
  @media (max-width: 350px) {
    @content;
  }
}

.footer-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../../../public/assets/image/bg-footer.png'),
    linear-gradient(180deg, #121212 0%, #121212 100%);
  background-color: transparent;
  padding: 60px 110px;

  @include xl-down-device() {
    padding: 60px 75px;
  }

  @include md-down-device() {
    padding: 40px 40px;
  }

  @include sm-down-device() {
    padding: 40px 20px;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include md-down-device() {
      flex-direction: column;
      gap: 40px;
    }

    .company-container {
      display: flex;
      flex-direction: column;

      .company-title {
        font-weight: 600;
        font-size: 25px;
        color: #ffffff;
        letter-spacing: 0.9px;
        font-family: $font-hankenGrotesk;
        border-bottom: 2px solid transparent;
        position: relative;
        padding-bottom: 5px;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 105px;
          height: 2px;
          background: linear-gradient(
            to right,
            #0b0c0c,
            #ffffff,
            rgba(255, 255, 255, 0)
          );
          opacity: 0.4;
          border-radius: 10px;
        }
      }

      .company-list {
        margin-top: 30px;
        display: flex;
        flex-direction: column;

        p {
          margin: 0;
          font-weight: 500;
          font-size: 17px;
          color: #ffffff;
          letter-spacing: 0.34px;
          font-family: $font-hankenGrotesk;
          padding-bottom: 10px;
          cursor: pointer;
          transition: all 0.5s ease;

          &:hover {
            color: #3067ff;
          }
        }
      }
    }

    .contact-container {
      display: flex;
      flex-direction: column;

      .contact-title {
        font-weight: 600;
        font-size: 25px;
        color: #ffffff;
        letter-spacing: 0.9px;
        font-family: $font-hankenGrotesk;
        border-bottom: 2px solid transparent;
        position: relative;
        padding-bottom: 5px;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 105px;
          height: 2px;
          background: linear-gradient(
            to right,
            #0b0c0c,
            #ffffff,
            rgba(255, 255, 255, 0)
          );
          opacity: 0.4;
          border-radius: 10px;
        }
      }

      .contact-content {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .contact-item {
          display: flex;
          align-items: center;
          gap: 20px;

          .contact-icon {
            position: relative;
            svg {
              fill: #3067ff;
            }
            &::after {
              content: '';
              height: 35px;
              width: 1px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: -10px;
            }
          }
          .contact-content-detail {
            display: flex;
            flex-direction: column;

            span {
              color: #3067ff;
              font-size: 13px;
              font-weight: 400;
              font-family: $font-inter;
            }
            .content-title a {
              margin: 0;
              color: #ffffff;
              font-size: 18px;
              font-weight: 600;
              font-family: $font-hankenGrotesk;
              text-decoration: none;
              letter-spacing: 0.54px;
              outline: none;
              transition: all 0.5s ease;

              &:hover {
                color: #3067ff;
              }

              &.email {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .social-media-container {
      display: flex;
      flex-direction: column;

      @include md-down-device() {
        margin-top: 20px;
      }
      @include lg-up-device() {
        padding-right: 50px;
      }

      .social-media-title {
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
        font-family: $font-hankenGrotesk;
        text-decoration: underline;
      }

      .social-media-content {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 25px;

        .social-media-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 6px;
          cursor: pointer;

          .icon {
            height: 20px;
          }

          p {
            margin: 0;
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            font-family: $font-hankenGrotesk;
          }
        }
      }
    }
  }
}

// dark mode
body.lp-dark {
  .footer-container {
    background-image: unset;
    background-color: $light-black-color;

    .content {
      .company-container .company-list p {
        color: $lp-dark-text-color;
      }
      .contact-container
        .contact-content
        .contact-item
        .contact-content-detail
        .content-title
        a {
        color: $lp-dark-text-color;
      }
    }
  }
}
