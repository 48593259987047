@import "@styles/variables";
@import '../styles/variable';

// Mixins Css
@mixin eighteen-down-device {
  @media (max-width: 1799px) {
    @content;
  }
}
@mixin seventeen-down-device {
  @media (max-width: 1699px) {
    @content;
  }
}

@mixin fifteen-down-device {
  @media (max-width: 1499px) {
    @content;
  }
}

@mixin xxl-down-device {
  @media (max-width: 1399px) {
    @content;
  }
}

@mixin xl-down-device {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin xxl-device {
  @media (min-width: 1400px) and (max-width: 1599px) {
    @content;
  }
}

@mixin xl-device {
  @media (min-width: 1200px) and (max-width: 1399px) {
    @content;
  }
}
@mixin lg-device {
  @media (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}

@mixin xl-up-device {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin lg-up-device {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin lg-down-device {
  @media (max-width: 991px) {
    @content;
  }
}

// md-device============
@mixin md-device {
  @media (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin xxl-up-device {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin md-up-device {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin md-down-device {
  @media (max-width: 767px) {
    @content;
  }
}

// sm-device
@mixin sm-device {
  @media (min-width: 576px) and (max-width: 768px) {
    @content;
  }
}

@mixin sm-down-device {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin sm-mobile-device {
  @media (max-width: 425px) {
    @content;
  }
}

@mixin big-mobile-device {
  @media (min-width: 375px) and (max-width: 576px) {
    @content;
  }
}

@mixin threefifty-down-device() {
  @media (max-width: 350px) {
    @content;
  }
}

// effect
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.feature-container {
  margin-top: 100px;
  margin-bottom: 50px;

  @include md-down-device() {
    margin-top: 70px;
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;

    h1 {
      color: #07226d;
      font-family: $font-hankenGrotesk;
      font-size: 50px;
      font-weight: 800;
      line-height: 1.2;
      margin-bottom: 0;
      text-align: center;
      @include md-down-device() {
        font-size: 50px;
      }
      @include sm-down-device() {
        font-size: 38px;
      }
    }
  }

  .subtitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    p {
      margin: 20px 0 0;
      font-family: $font-inter;
      color: #07226d;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.3;
      text-align: center;
      width: 600px;
      @include md-down-device() {
        font-size: 19px;
        width: 400px;
      }
      @include sm-down-device() {
        margin: 10px 0 0;
        font-size: 16px;
        width: 310px;
      }
    }
  }

  .content-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 50px 70px;
    margin-top: 40px;
    // background-color: yellow;
    @include lg-down-device {
      padding: 30px 50px;
    }
    @include sm-down-device() {
      padding: 10px 20px;
    }

    .item {
      width: 45%;
      display: flex;
      flex-direction: column;
      text-align: left;
      gap: 8px;
      align-self: center;
      animation-duration: 1500ms;
      animation-delay: 400ms;
      padding-left: 50px;
      padding-right: 50px;

      @include lg-down-device() {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
      }

      .icon-container {
        display: flex;
        flex-direction: row;

        @include md-down-device() {
          display: none;
        }

        .icon {
          width: 55px;
          height: 55px;
          background-color: #eff7ff;
          border-radius: 999px;
          padding: 6px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      }

      .content {
        display: flex;
        flex-direction: column;

        h1 {
          color: $primary-color1;
          font-family: $font-hankenGrotesk;
          font-size: 35px;
          font-weight: 700;
          line-height: 1.1;
          margin-bottom: 0;
          text-align: left;

          @include md-down-device() {
            font-size: 30px;
          }
          @include sm-down-device() {
            font-size: 24px;
          }
        }

        p {
          color: rgba(7, 34, 109, 1);
          font-family: $font-hankenGrotesk;
          font-size: 17px;
          font-weight: 400;
          line-height: normal;
          margin-top: 5px;
          margin-bottom: 0;
          text-align: left;
          @include lg-down-device() {
            font-size: 16px;
          }
          @include md-down-device() {
            font-size: 16px;
          }
          @include sm-down-device() {
            font-size: 14px;
          }
        }
      }
    }
    .image-container {
      width: 49%;
      background-color: #ffffff;
      border-radius: 20px;
      animation-duration: 1500ms;
      animation-delay: 400ms;
      box-shadow: 0px 4px 8px rgba(107, 102, 102, 0.25);
      overflow: hidden;

      @include lg-down-device() {
        width: 100%;
        margin-top: 15px;
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
        &::-webkit-media-controls-panel {
          background-image: linear-gradient(
            180deg,
            transparent,
            transparent,
            rgba(0, 0, 0, 0.6) 75%
          ) !important;
        }
      }
    }
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fadeInRight {
  animation-name: fadeInRight;
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

// dark mode
body.lp-dark {
  .feature-container {
    .title {
      h1 {
        color: $white-color;
      }
    }
    .subtitle {
      p {
        color: $lp-dark-text-color;
      }
    }
    .content-list {
      .item {
        .icon-container .icon {
          background-color: $light-black-color;
        }
        .content {
          h1 {
            // color: $white-color;
          }
          p {
            color: $lp-dark-text-color;
          }
        }
      }
      .image-container {
        background-color: black !important;

        video {
          background-color: black !important;
        }
      }
    }
  }
}
