@import "@styles/variables";
@import '../styles/variable';

// Mixins Css
@mixin eighteen-down-device {
  @media (max-width: 1799px) {
    @content;
  }
}
@mixin seventeen-down-device {
  @media (max-width: 1699px) {
    @content;
  }
}

@mixin fifteen-down-device {
  @media (max-width: 1499px) {
    @content;
  }
}

@mixin xxl-down-device {
  @media (max-width: 1350px) {
    @content;
  }
}

@mixin xl-down-device {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin xxl-device {
  @media (min-width: 1400px) and (max-width: 1599px) {
    @content;
  }
}

@mixin xl-device {
  @media (min-width: 1200px) and (max-width: 1399px) {
    @content;
  }
}
@mixin lg-device {
  @media (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}

@mixin xl-up-device {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin lg-up-device {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin lg-down-device {
  @media (max-width: 991px) {
    @content;
  }
}

// md-device============
@mixin md-device {
  @media (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin xxl-up-device {
  @media (min-width: 1350px) {
    @content;
  }
}

@mixin md-up-device {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin md-down-device {
  @media (max-width: 767px) {
    @content;
  }
}

// sm-device
@mixin sm-device {
  @media (min-width: 576px) and (max-width: 768px) {
    @content;
  }
}

@mixin sm-down-device {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin sm-mobile-device {
  @media (max-width: 425px) {
    @content;
  }
}

@mixin big-mobile-device {
  @media (min-width: 375px) and (max-width: 576px) {
    @content;
  }
}

@mixin threefifty-down-device() {
  @media (max-width: 350px) {
    @content;
  }
}

.service-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../../../public/assets/image/home3-testimonial-bg.png'),
    linear-gradient(96deg, #fbd8ff 1.56%, #dedbfd 50.41%, #c5ccff 98.25%);
  padding: 70px 0px 50px 0px;

  @include md-down-device() {
    padding: 50px 0px 50px 0px;
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;

    h1 {
      color: rgba(7, 34, 109, 1);
      font-family: $font-hankenGrotesk;
      font-size: 50px;
      font-weight: 800;
      line-height: 1.2;
      margin-bottom: 0;
      text-align: center;
      @include md-down-device() {
        font-size: 40px;
      }
      @include sm-down-device() {
        font-size: 38px;
      }

      .br {
        display: none;
        @include md-down-device() {
          display: block;
        }
      }
    }
  }

  .service-list-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: 50px 50px;

    @include sm-device() {
      max-width: 540px;
      margin: 0 auto;
    }

    @include md-device() {
      max-width: 720px;
      margin: 0 auto;
    }

    @include lg-device() {
      max-width: 960px;
      margin: 0 auto;
      gap: 15px;
    }

    @include xxl-up-device() {
      max-width: 1320px;
      margin: 0 auto;
    }

    @include xxl-down-device() {
      max-width: 1140px;
      margin: 0 auto;
    }

    @include xl-down-device() {
      row-gap: 20px;
    }

    @include sm-down-device() {
      flex-direction: column;
      padding: 50px 15px 10px 15px;
      gap: 15px;
    }

    .item {
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 1);
      padding: 30px 20px;
      width: calc((100% - 50px) / 4);

      @include xl-down-device() {
        width: calc((100% - 20px) / 2);
      }
      @include lg-down-device() {
        width: calc((100% - 20px) / 2);
      }
      @include sm-down-device() {
        width: 100%;
        padding: 25px 20px;
      }

      &:hover {
        transform: translateY(-10px) !important;
        transition: all 0.5s ease-out;
      }

      .icon-container {
        display: flex;
        flex-direction: row;

        .icon {
          width: 86px;
          height: 86px;
          background-color: #eff7ff;
          border-radius: 999px;
          padding: 6px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        margin-top: 25px;

        @include md-down-device() {
          margin-top: 15px;
        }
        @include sm-down-device() {
          margin-top: 10px;
        }

        h1 {
          color: rgba(7, 34, 109, 1);
          font-family: $font-hankenGrotesk;
          font-size: 28px;
          font-weight: 700;
          line-height: 1.1;
          margin-bottom: 0;
          text-align: left;
          letter-spacing: 0.1px;

          @include lg-down-device() {
            font-size: 28px;
          }
          @include md-down-device() {
            font-size: 27px;
          }
          @include sm-down-device() {
            font-size: 25px;
          }
        }

        p {
          color: rgba(7, 34, 109, 1);
          font-family: $font-hankenGrotesk;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.5;
          margin-top: 15px;
          margin-bottom: 0;
          text-align: left;

          @include md-down-device() {
            padding-right: 25px;
            margin-top: 10px;
          }
          @include sm-down-device() {
            font-size: 14px;
          }
        }
      }
    }
  }
}

// dark mode
body.lp-dark {
  .service-container {
    background-image: unset;
    background-color: $light-black-color;

    .title {
      h1 {
        color: $white-color;
      }
    }
    .service-list-container {
      .item {
        background-color: $lp-dark-white-color;
        outline: none !important;

        .icon-container {
          .icon {
            background-color: $light-black-color !important;
          }
        }

        .content {
          h1 {
            color: $white-color;
          }
          p {
            color: $lp-dark-text-color;
          }
        }
      }
    }
  }
}
