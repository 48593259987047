@import "@styles/variables";
@import "@styles/variables";
@import "@styles/mixins";

.login-page {
  .forgot {
    margin-bottom: 20px;
  }

  .message {
    margin-bottom: 5px;
    font-size: 12px;
    display: inline-block;

    &.error {
      color: rgb(155, 0, 0);
    }

    &.loading {
      font-size: $primary-color;
    }
  }

  .input.form-control {
    font-size: 14px;
    height: 40px;
    color: rgba(0, 0, 0, 0.7);

    ::placeholder {
      font-size: 14px;
    }
  }

  .form-check-label {
    font: {
      size: 12px;
      weight: 400;
    }

    margin: 0;
  }

  .remember-check {
    border-radius: 6px;
    border: 1px solid $gray;
    @include square(16px);
  }

  .or-container {
    @include flex-container($align: center);
    width: 100%;
    color: $gray7;
    font-size: 12px;
    gap: 8px;
    margin: 20px 0;

    div {
      flex: 1;
      height: 1px;
      background: $gray;
    }
  }

  .google-button {
    @include flex-container($align: center, $justify: center);
    gap: 5px;
    background: white;
    color: $dark-blue;
    font-size: 14px;
    border: 1px solid $gray;
    width: calc(100% - 28px);
  }
}

.full-screen-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
  background-color: #f9fafb;
}

.titleH1 {
  color: #0080fc;
  font-weight: 400;
  font-size: 28px;
}

labeL {
  color: #210d4a;
}

::placeholder,
.btn-sm,
.sign,
.labeL {
  font-size: 14px;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid #dee2e6;
}

form a {
  text-decoration: none;
  font-size: 12px;
  color: #210d4a;
  font-weight: bold;
}

.btn-sm {
  background: white;
  color: black;
  border: 1px solid #ccc;
  width: 90%;
  border-radius: 6px;
  width: 90%;
  font-size: 14px;
}

.btn-block {
  width: 100%;
  font-size: 15px;
}

// button:focus:not(:focus-visible) {
//   outline: 0;
//   background: none;
//   color: black;
// }

input:active {
  box-shadow: none;
}

.password-input-container img {
  top: 73%;
  right: 25px;
  cursor: pointer;
}

.or-separator {
  color: #dee4ed;
}

.or-separator::before,
.or-separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #dee4ed;
}

.sign {
  color: #0c2536;
}

.sign a {
  color: #0080fc;
}

.vision-enter {
  position: absolute;
  top: 60%;
  right: 20px;
}

.vision-confirm {
  position: absolute;
  top: 59%;
  right: 20px;
}

.image-logo {
  width: 123.73px !important;
}

.icone-pays {
  position: absolute;
  z-index: 10;
  top: 10px;
  left: 15px;
}

.bouttonG {
  color: #0c2536;
}

@include respond-to("tablet-portrait") {
  .login-page {
    .google-button {
      width: 100%;
    }
  }
}

@media (min-width: 992px) {
  .image-logo {
    width: 288px !important;
  }

  .titleH1 {
    font-size: 32px;
  }

  .content-login {
    width: 50% !important;
  }
}