@import "@styles/variables";
@import '@styles/variables';
@import '@styles/mixins';

.log-table-component {
  outline: 1px solid $stock1;
  // width: 100%;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (min-width: 900px) {
    --rowWidth: calc(100vw - 230px - 40px - 40px);
    height: calc(100vh - 84px - 62.4px - 16px) !important;
    @media (min-width: 1100px) {
      --rowWidth: calc(100vw - 230px - 40px - 60px) !important;
      height: calc(100vh - 94px - 62.4px - 16px) !important;
    }
    position: relative;
    table-layout: fixed;
    width: var(--rowWidth) !important;
    overflow-x: hidden;

    thead {
      display: table-header-group;
      background-color: #f9fafb;
      tr {
        display: table-row;
        position: relative;

        th {
          padding: 0px 10px !important;
          height: 45px !important;

          &:first-child {
            @media (min-width: 1200px) {
              padding-left: 25px !important;
            }
          }
          &:last-child {
            @media (min-width: 1200px) {
              padding-right: 20px !important;
            }
          }
          &.time {
            min-width: 20% !important;
            max-width: 20% !important;
            width: 20% !important;
          }
          &.type,
          &.user {
            min-width: calc(25% / 2) !important;
            max-width: calc(25% / 2) !important;
            width: calc(25% / 2) !important;
          }
          &.log {
            min-width: 50% !important;
            max-width: 50% !important;
            width: 50% !important;
          }
        }
      }
    }
    tbody {
      display: block;
      height: 100%;
      width: var(--rowWidth) !important;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 3px;
        background-color: #e4e4e4 !important;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #b6b6b6;
        border-radius: 999px !important;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #b6b6b6;
        cursor: pointer;
      }

      tr {
        display: table;
        table-layout: fixed;
        width: var(--rowWidth);
      }
      td {
        padding: 20px 10px !important;

        &:first-child {
          @media (min-width: 1200px) {
            padding-left: 25px !important;
          }
        }
        &:last-child {
          @media (min-width: 1200px) {
            padding-right: 20px !important;
          }
        }

        &.time {
          padding-left: 25px !important;
        }
        &.time {
          min-width: 20% !important;
          max-width: 20% !important;
          width: 20% !important;
          word-break: break-all;
        }
        &.user,
        &.activity-type {
          min-width: calc(25% / 2) !important;
          max-width: calc(25% / 2) !important;
          width: calc(25% / 2) !important;
        }
        &.log {
          min-width: 50% !important;
          max-width: 50% !important;
          width: 50% !important;
        }
      }
    }
  }

  .tooltip-btn {
    margin-bottom: 2px;
    position: relative;
    z-index: 1;
  }

  input[type='checkbox'] {
    @include square(20px);
  }

  .view-detail {
    display: none;

    font: {
      size: 12px;
      weight: 500;
    }
  }

  tr:not(:last-child),
  thead {
    border-bottom: 1px solid $stock1;
  }
  th {
    // display: flex;
    color: $gray7;
    //text-transform: capitalize;
    background-color: $gray2;

    padding: 13px 0 {
      left: 24px;
    }

    > span {
      @include flex-container($align: center);
      gap: 5px;

      font: {
        weight: 500;
        size: 12px;
      }

      > svg {
        @include square(16px);
      }
    }
  }

  .loading-row {
    width: 100%;

    td {
      width: 80vw;
    }

    svg {
      margin: 10px auto;
      display: block;
      animation: loading 1.2s linear infinite reverse;
    }
  }

  // Style loading svg
  tr {
    // position: relative;
    // z-index: 100;
    &.active {
      background: #e8f2fd !important;
      border-color: $primary2;
    }

    td {
      color: $base-black;
      font-size: 14px;

      padding: 16px 0 {
        left: 24px;
        right: 2px;
      }
    }
    .check {
      padding: 0;
      // position: relative;
      pointer-events: all;
      label {
        cursor: pointer;
        height: 100%;
        width: 100%;
        // position: absolute;
        top: 0;
        left: 0;
        padding: 26px 0 {
          left: 24px;
        }
      }
    }
    .link-wrapper {
      cursor: pointer;
      display: contents;
    }
  }

  th {
    position: relative;

    &:not(:first-of-type) {
      min-width: 150px;
    }

    &.open {
      &::before {
        content: '';
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: transparent;
        left: 0;
        top: 0;
        z-index: 101;
      }
      .sorting-options {
        display: block;
      }
    }

    max-width: 16.67%;

    > span:not(.unsorted) {
      cursor: pointer;
    }

    .sorting-options {
      display: none;
      background: white;
      top: 36px;
      left: 8px;
      background: white;
      padding: 4px 0;
      border-radius: 6px;
      box-shadow: 4px 4px 16px 0px #00000014;
      border: 1px solid #f6f6f6;
      transition: 0.8s;
      position: absolute;
      z-index: 101;

      div {
        padding: 10px;
        cursor: pointer;

        svg:last-child {
          opacity: 0;
        }

        &.selected {
          background: #f5f6f7;

          svg:last-child {
            opacity: 1;
          }
        }
        svg:first-child {
          margin-right: 27px;
        }
      }
    }
  }
}

@include respond-to('tablet-landscape') {
  .log-table-component {
    outline: 0;

    thead {
      display: none;
    }

    tr {
      font-size: 12px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 12px;
      border: 1px solid $stock1;
      border-radius: 8px;
      margin-bottom: 16px;
      pointer-events: none;

      &.active {
        border-color: $primary2;
        background: #e8f2fd !important;

        .check > input {
          border-color: $primary5;
        }

        td {
          border-color: white !important;
          border-right-color: white !important;
        }
      }
      &.no-checkbox {
        .amount {
          font-weight: 500;
        }

        .log-no {
          width: 100%;
        }

        .amount {
          // padding-bottom: 27px;
        }
      }

      .onClick {
        display: block;
      }

      %row {
        // padding-bottom: 6.5px;
      }

      %first-row {
        @extend %row;
        line-height: 16.8px;
        margin-bottom: 6px;
        font-weight: 500;
      }
      input[type='checkbox'] {
        @include square(16px);
        border: 1px solid $gray300;
      }
      .check {
        @extend %first-row;
        order: 1;
        width: 28px;
        height: 17px;
        label {
          padding: 0;
        }
      }
      .log-no {
        @extend %first-row;
        order: 2;
        width: calc(100% - 28px);
        height: 17px;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        .view-detail {
          display: unset;
          pointer-events: all;
        }
        .mobile-only {
          @media (max-width: 900px) {
            display: block;
            margin-left: 10px;
            color: #12b76a;
          }
        }
      }
      .desktop-only {
        @media (min-width: 901px) {
          display: block;
        }
      }
      %second-row {
        @extend %row;
        border-bottom: 1px solid $stock1;
        margin-bottom: 9.5px;
        padding-bottom: 7.5px;
        // height: 28px;
      }
      .buyer {
        @extend %second-row;
        margin-bottom: 9.5px;
        width: 70%;
        order: 3;
        font-size: 14px;
        line-height: 19.6px;
      }
      .amount {
        @extend %second-row;
        width: 30%;
        order: 4;
        font: {
          size: 12px;
          weight: 400;
        }

        line-height: 16.8px;
        @include flex-container(column, center, flex-end);
      }

      .date {
        order: 9;
      }

      .company.log-company {
        display: inline-block;
      }

      .company {
        white-space: nowrap;
        overflow: hidden;
        // display: inline-block !important;
        text-overflow: ellipsis;
      }

      $other-cols: company, date, overdue, due;

      @each $col in $other-cols {
        .#{$col} {
          @include flex-container(column, flex-start, center);
          gap: 2px;
          flex: 1;
          text-align: center;
          line-height: 16.8px;
          font: {
            weight: 500;
            size: 12px;
          }
          &::before {
            content: '#{$col}';
            //text-transform: capitalize;
            color: $gray7;
            font-weight: 400;
            display: block;
            text-transform: capitalize;
          }
        }
      }

      .overdue {
        order: 10;

        &::before {
          content: 'Overdue By' !important;
        }
      }
      .other:not(.last) {
        border-right: 1px solid $stock1;
        padding-right: 3px;
      }
      // .other.last{
      //   border-left: 1px solid $stock1;
      //   padding-left: 3px;
      // }
      // .company {
      //   @include flex-container(column);
      //   &::before {
      //     content: "Compnay";
      //   }
      // }
      td {
        order: 5;
        padding: 0;
      }
    }
  }
}

@keyframes loading {
  100% {
    transform: rotate(360deg);
  }
}

.log-batch-span {
  @media (min-width: 901px) {
    flex-direction: column;
  }
}

.batch-number-span {
  color: #12b76a;

  @media (max-width: 900px) {
    margin-left: 16px;
  }
}

.loglist-table-footer {
  font-size: 12px;
  color: gray;
  margin: 12px auto;
  display: flex;
  text-align: center;
  width: fit-content;
}

.log-body-table {
  & .log-mobile-view {
    position: relative;
    & .decoration {
      display: none;
      @media (max-width: 900px) {
        display: block;
        width: 5px;
        height: 80%;
        background-color: $primary2;
        position: absolute;
        left: 12px;
        top: 10%;
        border-radius: 12px;
      }
    }

    & .log {
      white-space: pre-line;
      a {
        text-decoration: none;
      }
    }

    & .user {
      font-size: 13px;
      & .user-sms {
        display: block;
        @media (min-width: 900px) {
          font-size: 13px !important;
          display: flex;
          flex-direction: column !important;
        }
        & .dec {
          @media (max-width: 900px) {
            padding-right: 4px;
          }
          @media (min-width: 900px) {
            display: none !important;
          }
        }
        & .phone {
          @media (max-width: 900px) {
            line-height: normal;
            align-self: center;
          }
        }
      }
    }

    @media (max-width: 900px) {
      display: grid !important;
      grid-template-columns: 1fr !important;
      justify-content: start !important;
      padding-left: 30px;
      border: none !important;
      font-size: 12px !important;

      & .user {
        order: 1;
        color: $light-blue;
        @media (max-width: 900px) {
          font-weight: 500;
          font-size: 13px !important;
        }
      }
      & .activity-type {
        order: 2;
        @media (max-width: 900px) {
          font-weight: 500;
          font-size: 12px !important;
        }
      }
      & .log {
        order: 3;
        line-height: normal;
        @media (max-width: 900px) {
          margin-top: 2px;
          font-size: 13px !important;
        }
      }
      & .time {
        order: 4;
        font-size: 13px;
        @media (max-width: 900px) {
          font-weight: 500;
          margin-top: 5px;
          font-size: 12px !important;
        }
      }
    }
  }
}
