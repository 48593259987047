@import "@styles/variables";
@import "@styles/variables";
@import "@styles/mixins";

.content-section-component {
  $radius: 8px;
  border-radius: $radius;
  margin-bottom: 16px;
  &.my-shadow {
    box-shadow: 2px 0px 4px 0px #0000001a;
    > header {
      color: $base-black;
    }
  }
  > header {
    background: $gray2;
    padding: 12px 20px;
    border: 1px solid $stock1;
    border-radius: $radius $radius 0 0;
    color: $base-black;
    font-weight: 500;
    font-size: 16px;
  }
  > main {
    padding: 16px;
    border: 1px solid $stock1;
    border-radius: 0 0 $radius $radius;
  }
}

@include respond-to("tablet-landscape") {
  .content-section-component {
    box-shadow: none !important;
    > header {
      font-size: 14px;
      border-bottom: 0;
    }
    > main {
      border-top: 0;
    }
  }
}
