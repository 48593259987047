@import "@styles/variables";
@import '@styles/variables';
@import '@styles/mixins';

.invoice-table-component {
  outline: 1px solid $stock1;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 900px) {
    tbody {
      tr {
        width: calc(100vw - 32px) !important;
      }
    }
  }

  @media (min-width: 900px) {
    --rowWidth: calc(100vw - 230px - 40px - 40px);
    height: calc(100vh - 84px - 62.4px - 16px) !important;
    @media (min-width: 1100px) {
      --rowWidth: calc(100vw - 230px - 40px - 60px) !important;
      height: calc(100vh - 94px - 62.4px - 16px) !important;
    }
    position: relative;
    table-layout: fixed;
    width: var(--rowWidth) !important;
    overflow-x: hidden;

    thead {
      display: table-header-group;
      background-color: #f9fafb;
      tr {
        display: table-row;
        position: relative;

        th {
          padding: 0px 10px !important;
          height: 54px !important;

          &:first-child {
            @media (min-width: 1200px) {
              padding-left: 20px !important;
            }
          }
          &:last-child {
            @media (min-width: 1200px) {
              padding-right: 20px !important;
            }
          }

          // invoicemode collections
        &.buyer.invoicemode-collections {
          min-width: calc(var(--rowWidth) * (20 / 100)) !important;
          max-width: calc(var(--rowWidth) * (20 / 100)) !important;
          width: calc(var(--rowWidth) * (20 / 100)) !important;
        }
        &.company.invoicemode-collections {
          min-width: calc(var(--rowWidth) * (17 / 100)) !important;
          max-width: calc(var(--rowWidth) * (17 / 100)) !important;
          width: calc(var(--rowWidth) * (17 / 100)) !important;
        }
        &.date.invoicemode-collections,
        &.due.invoicemode-collections,
        &.overdueby.invoicemode-collections,
        &.invoice.invoicemode-collections,
        &.amount.invoicemode-collections {
          min-width: calc(
            ((var(--rowWidth) * (63 / 100)) - 50px) / 5
          ) !important;
          max-width: calc(
            ((var(--rowWidth) * (63 / 100)) - 50px) / 5
          ) !important;
          width: calc(((var(--rowWidth) * (63 / 100)) - 50px) / 5) !important;
        }

        // invoicemode deliveries
        &.buyer.invoicemode-deliveries {
          min-width: calc(var(--rowWidth) * (28 / 100)) !important;
          max-width: calc(var(--rowWidth) * (28 / 100)) !important;
          width: calc(var(--rowWidth) * (28 / 100)) !important;
        }
        &.company.invoicemode-deliveries {
          min-width: calc(var(--rowWidth) * (20 / 100)) !important;
          max-width: calc(var(--rowWidth) * (20 / 100)) !important;
          width: calc(var(--rowWidth) * (20 / 100)) !important;
        }
        &.date.invoicemode-deliveries,
        &.invoice.invoicemode-deliveries,
        &.amount.invoicemode-deliveries {
          min-width: calc(
            ((var(--rowWidth) * (45 / 100)) - 50px) / 3
          ) !important;
          max-width: calc(
            ((var(--rowWidth) * (45 / 100)) - 50px) / 3
          ) !important;
          width: calc(((var(--rowWidth) * (45 / 100)) - 50px) / 3) !important;
        }
        &.items.invoicemode-deliveries {
          min-width: calc(var(--rowWidth) * (7 / 100)) !important;
          max-width: calc(var(--rowWidth) * (7 / 100)) !important;
          width: calc(var(--rowWidth) * (7 / 100)) !important;
          overflow: visible;
        }

          // deliveries
          &.checkbox-th {
            min-width: 50px !important;
            max-width: 50px !important;
            width: 50px !important;
          }
          &.items.deliveries {
            min-width: 8% !important;
            max-width: 8% !important;
            width: 8% !important;
            overflow: visible;
          }
          &.invoiceno.deliveries {
            min-width: 15% !important;
            max-width: 15% !important;
            width: 15% !important;
          }
          &.date.deliveries,
          &.amount.deliveries {
            min-width: calc(((var(--rowWidth) * 29%) - 50px) / 2) !important;
            max-width: calc(((var(--rowWidth) * 29%) - 50px) / 2) !important;
            width: calc(((var(--rowWidth) * 29%) - 50px) / 2) !important;
          }
          &.buyer.deliveries {
            min-width: 28% !important;
            max-width: 28% !important;
            width: 28% !important;
          }
          &.company.deliveries {
            min-width: 20% !important;
            max-width: 20% !important;
            width: 20% !important;
          }

          // collections
          &.invoiceno.collections,
          &.date.collections,
          &.amount.collections,
          &.due.collections,
          &.overdueby.collections {
            min-width: calc((55% - 50px) / 5) !important;
            max-width: calc((55% - 50px) / 5) !important;
            width: calc((55% - 50px) / 5) !important;
          }
          &.buyer.collections {
            min-width: 25% !important;
            max-width: 25% !important;
            width: 25% !important;
          }
          &.company.collections {
            min-width: 20% !important;
            max-width: 20% !important;
            width: 20% !important;
          }

          // completed
          &.invoiceno.completed {
            padding-left: 25px !important;
          }
          &.invoiceno.completed,
          &.date.completed,
          &.amount.completed {
            min-width: calc(45% / 3) !important;
            max-width: calc(45% / 3) !important;
            width: calc(45% / 3) !important;
          }
          &.buyer.completed {
            min-width: 30% !important;
            max-width: 30% !important;
            width: 30% !important;
          }
          &.company.completed {
            min-width: 25% !important;
            max-width: 25% !important;
            width: 25% !important;
          }
        }
      }
    }
    tbody {
      display: block;
      height: 100%;
      width: var(--rowWidth) !important;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 3px;
        background-color: #e4e4e4 !important;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #b6b6b6;
        border-radius: 999px !important;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #b6b6b6;
        cursor: pointer;
      }

      tr {
        display: table;
        table-layout: fixed;
        width: var(--rowWidth) !important;
      }
      td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 20px 10px !important;

        &:first-child {
          @media (min-width: 1200px) {
            padding-left: 20px !important;
          }
        }
        &:last-child {
          @media (min-width: 1200px) {
            padding-right: 20px !important;
          }
        }

        // invoicemode collections
        &.buyer.invoicemode-collections {
          min-width: calc(var(--rowWidth) * (20 / 100)) !important;
          max-width: calc(var(--rowWidth) * (20 / 100)) !important;
          width: calc(var(--rowWidth) * (20 / 100)) !important;
        }
        &.invoice-company.invoicemode-collections {
          min-width: calc(var(--rowWidth) * (17 / 100)) !important;
          max-width: calc(var(--rowWidth) * (17 / 100)) !important;
          width: calc(var(--rowWidth) * (17 / 100)) !important;
        }
        &.date.invoicemode-collections,
        &.due.invoicemode-collections,
        &.overdue.invoicemode-collections,
        &.invoice-no.invoicemode-collections,
        &.amount.invoicemode-collections {
          min-width: calc(
            ((var(--rowWidth) * (63 / 100)) - 50px) / 5
          ) !important;
          max-width: calc(
            ((var(--rowWidth) * (63 / 100)) - 50px) / 5
          ) !important;
          width: calc(((var(--rowWidth) * (63 / 100)) - 50px) / 5) !important;
        }

        // invoicemode deliveries
        &.buyer.invoicemode-deliveries {
          min-width: calc(var(--rowWidth) * (28 / 100)) !important;
          max-width: calc(var(--rowWidth) * (28 / 100)) !important;
          width: calc(var(--rowWidth) * (28 / 100)) !important;
        }
        &.invoice-company.invoicemode-deliveries {
          min-width: calc(var(--rowWidth) * (20 / 100)) !important;
          max-width: calc(var(--rowWidth) * (20 / 100)) !important;
          width: calc(var(--rowWidth) * (20 / 100)) !important;
        }
        &.date.invoicemode-deliveries,
        &.invoice-no.invoicemode-deliveries,
        &.amount.invoicemode-deliveries {
          min-width: calc(
            ((var(--rowWidth) * (45 / 100)) - 50px) / 3
          ) !important;
          max-width: calc(
            ((var(--rowWidth) * (45 / 100)) - 50px) / 3
          ) !important;
          width: calc(((var(--rowWidth) * (45 / 100)) - 50px) / 3) !important;
        }
        &.Icon.invoicemode-deliveries {
          min-width: calc(var(--rowWidth) * (7 / 100)) !important;
          max-width: calc(var(--rowWidth) * (7 / 100)) !important;
          width: calc(var(--rowWidth) * (7 / 100)) !important;
          overflow: visible;
        }

        // deliveries
        &.check,
        &.check.deliveries {
          min-width: 50px !important;
          max-width: 50px !important;
          width: 50px !important;
        }
        &.Icon.deliveries {
          min-width: 8% !important;
          max-width: 8% !important;
          width: 8% !important;
          overflow: visible;
        }
        &.invoice.deliveries {
          min-width: 15% !important;
          max-width: 15% !important;
          width: 15% !important;
        }
        &.date.deliveries,
        &.amount.deliveries {
          min-width: calc(
            ((var(--rowWidth) * (29 / 100)) - 50px) / 2
          ) !important;
          max-width: calc(
            ((var(--rowWidth) * (29 / 100)) - 50px) / 2
          ) !important;
          width: calc(((var(--rowWidth) * (29 / 100)) - 50px) / 2) !important;
        }
        &.buyer.deliveries {
          min-width: calc(var(--rowWidth) * (28 / 100)) !important;
          max-width: calc(var(--rowWidth) * (28 / 100)) !important;
          width: calc(var(--rowWidth) * (28 / 100)) !important;
        }
        &.invoice-company.deliveries {
          min-width: 20% !important;
          max-width: 20% !important;
          width: 20% !important;
        }
        &.invoice.deliveries {
          padding: 0px 10px !important;
        }
        &.invoice-no {
          padding: 0px 10px !important;
        }

        // collections
        &.check.collections {
          min-width: 50px !important;
          max-width: 50px !important;
          width: 50px !important;
        }
        &.invoice-no.collections {
          padding: 0px 10px !important;
        }
        &.invoice-no.collections,
        &.date.collections,
        &.amount.collections,
        &.due.collections,
        &.overdue.collections {
          min-width: calc(
            ((var(--rowWidth) * (55 / 100)) - 50px) / 5
          ) !important;
          max-width: calc(
            ((var(--rowWidth) * (55 / 100)) - 50px) / 5
          ) !important;
          width: calc(((var(--rowWidth) * (55 / 100)) - 50px) / 5) !important;
          white-space: wrap !important;
          word-break: break-all;
        }
        &.buyer.collections {
          min-width: calc(var(--rowWidth) * (25 / 100)) !important;
          max-width: calc(var(--rowWidth) * (25 / 100)) !important;
          width: calc(var(--rowWidth) * (25 / 100)) !important;
        }
        &.invoice-company.collections {
          min-width: 20% !important;
          max-width: 20% !important;
          width: 20% !important;
        }

        // completed
        &.invoice-no.completed {
          padding: 0px 25px !important;
        }
        &.invoice-no.completed,
        &.date.completed,
        &.amount.completed {
          min-width: calc(45% / 3) !important;
          max-width: calc(45% / 3) !important;
          width: calc(45% / 3) !important;
          white-space: wrap !important;
          word-break: break-all;
        }
        &.buyer.completed {
          min-width: calc(var(--rowWidth) * (30 / 100)) !important;
          max-width: calc(var(--rowWidth) * (30 / 100)) !important;
          width: calc(var(--rowWidth) * (30 / 100)) !important;
        }
        &.company.completed {
          min-width: 25% !important;
          max-width: 25% !important;
          width: 25% !important;
        }
      }
    }
  }

  .tooltip-btn {
    margin-bottom: 2px;
    position: relative;
    z-index: 1;
  }

  input[type='checkbox'] {
    @include square(20px);
  }

  .view-detail {
    display: none;

    font: {
      size: 12px;
      weight: 500;
    }
  }
  p {
    font-size: 12px;
    color: rgb(33, 13, 74);
    margin: 0px;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: auto;
  }

  tr:not(:last-child),
  thead {
    @media (max-width: 900px) {
      border: 1px solid $stock1;
    }
    @media (min-width: 900px) {
      border-bottom: 1px solid $stock1;
    }
  }

  th {
    // display: flex;
    color: $gray7;
    //text-transform: capitalize;
    background-color: $gray2;

    padding: 13px 0 {
      left: 24px;
    }

    > span {
      @include flex-container($align: center);
      gap: 5px;

      font: {
        weight: 500;
        size: 12px;
      }

      > svg {
        @include square(16px);
        overflow: visible;
      }
    }
  }

  .loading-row {
    width: 100%;

    td {
      width: 80vw;
    }

    svg {
      margin: 10px auto;
      display: block;
      animation: loading 1.2s linear infinite reverse;
    }
  }

  // Style loading svg
  tr {
    // position: relative;
    // z-index: 100;
    &.active {
      background: #e8f2fd !important;
      border-color: $primary2;
    }

    td {
      color: $base-black;
      font-size: 14px;

      padding: 26px 0 {
        left: 24px;
        right: 2px;
      }
    }

    .check {
      padding: 0;
      // position: relative;
      pointer-events: all;

      label {
        cursor: pointer;
        height: 100%;
        width: 100%;
        // position: absolute;
        top: 0;
        left: 0;

        padding: 26px 0 {
          left: 0px;
        }
      }
    }

    .link-wrapper {
      cursor: pointer;
      display: contents;
    }
  }

  th {
    position: relative;

    &:not(:first-of-type) {
      // min-width: 112px;
    }

    &.open {
      &::before {
        content: '';
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: transparent;
        left: 0;
        top: 0;
        z-index: 101;
      }

      .sorting-options {
        display: block;
      }
    }

    // max-width: 16.67%;

    > span:not(.unsorted) {
      cursor: pointer;
    }

    .sorting-options {
      display: none;
      background: white;
      top: 36px;
      left: 8px;
      background: white;
      padding: 4px 0;
      border-radius: 6px;
      box-shadow: 4px 4px 16px 0px #00000014;
      border: 1px solid #f6f6f6;
      transition: 0.8s;
      position: absolute;
      z-index: 101;

      div {
        padding: 10px;
        cursor: pointer;

        svg:last-child {
          opacity: 0;
        }

        &.selected {
          background: #f5f6f7;

          svg:last-child {
            opacity: 1;
          }
        }

        svg:first-child {
          margin-right: 27px;
        }
      }
    }
  }
}

@include respond-to('tablet-landscape') {
  .invoice-table-component {
    outline: 0;

    thead {
      display: none;
    }

    tr {
      font-size: 12px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 12px;
      border-radius: 8px;
      margin-bottom: 16px;
      pointer-events: none;

      &.active {
        border-color: $primary2;
        background: #e8f2fd !important;

        .check > input {
          border-color: $primary5;
        }

        td {
          border-color: white !important;
          border-right-color: white !important;
        }
      }

      &.no-checkbox {
        .amount {
          font-weight: 500;
        }

        .invoice-no {
          width: 100%;
        }

        .amount {
          // padding-bottom: 27px;
        }
      }

      .onClick {
        display: block;
      }

      %row {
        // padding-bottom: 6.5px;
      }

      %first-row {
        @extend %row;
        line-height: 16.8px;
        margin-bottom: 6px;
        font-weight: 500;
      }

      input[type='checkbox'] {
        @include square(16px);
        border: 1px solid $gray300;
      }

      .check {
        @extend %first-row;
        order: 1;
        width: 28px;
        height: 17px;

        label {
          padding: 0;
        }
      }

      .invoice-no {
        @extend %first-row;
        order: 2;
        width: calc(100% - 28px);
        height: 17px;
        font-size: 12px;
        display: flex;
        justify-content: space-between;

        .view-detail {
          display: unset;
          pointer-events: all;
        }

        .mobile-only {
          @media (max-width: 900px) {
            display: block;
            margin-left: 10px;
            color: #12b76a;
          }
        }
      }

      .desktop-only {
        @media (min-width: 901px) {
          display: block;
        }
      }

      %second-row {
        @extend %row;
        border-bottom: 1px solid $stock1;
        margin-bottom: 9.5px;
        padding-bottom: 7.5px;
        // height: 28px;
      }

      .buyer {
        @extend %second-row;
        flex-wrap: wrap;
        padding-bottom: 8px !important;
        margin-bottom: 9.5px;
        width: 70%;
        order: 3;
        font-size: 14px;
        line-height: 19.6px;
        p {
          width: auto;
        }
      }

      .amount {
        @extend %second-row;
        width: 30%;
        order: 4;

        font: {
          size: 12px;
          weight: 400;
        }

        line-height: 16.8px;
        @include flex-container(column, center, flex-end);
      }

      .date {
        order: 9;
        max-width: 120px;
      }
      .Icon {
        padding: 0px 10px;
        padding-left: 13px;
      }

      .company.invoice-company {
        display: inline-block;
      }

      .company {
        white-space: nowrap;
        overflow: hidden;
        // display: inline-block !important;
        text-overflow: ellipsis;
      }

      $other-cols: company, date, overdue, due;

      @each $col in $other-cols {
        .#{$col} {
          @include flex-container(column, flex-start, center);
          gap: 2px;
          flex: 1;
          border-right: 1px solid $stock1;
          text-align: center;
          line-height: 16.8px;

          font: {
            weight: 500;
            size: 12px;
          }

          &::before {
            content: '#{$col}';
            //text-transform: capitalize;
            color: $gray7;
            font-weight: 400;
            display: block;
            text-transform: capitalize;
          }
        }
      }

      .overdue {
        order: 10;

        &::before {
          content: 'Overdue By' !important;
        }
      }

      .other:not(.last) {
        border-right: 1px solid $stock1;
        padding-right: 3px;
      }

      // .other.last{
      //   border-left: 1px solid $stock1;
      //   padding-left: 3px;
      // }
      // .company {
      //   @include flex-container(column);
      //   &::before {
      //     content: "Compnay";
      //   }
      // }
      td {
        order: 7;
        padding-left: 0px;
        padding-right: 0px;
        align-items: center;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }
  }
}

@keyframes loading {
  100% {
    transform: rotate(360deg);
  }
}

.invoice-batch-span {
  @media (min-width: 901px) {
    flex-direction: column;
  }
}

.batch-number-span {
  color: #12b76a;

  @media (max-width: 900px) {
    margin-left: 16px;
  }
}

.generic-modal-header {
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
}

.Model_body {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: #210d4a;

  @media (max-width: 900px) {
    max-width: 245px;
  }
}

.invoice_modal-body {
  overflow-y: auto;
}

.Model-body_border_b {
  border-bottom: 1px solid #eaecf0;
}

.Product_model_header {
  padding: 16px 0 16px 6px;
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;

  .title {
    color: #0d6efd;
  }

  .title-font {
    font-size: 12px;
  }

  .title-body {
    color: #210d4a;
    font-size: 14px;
  }
}

.Close_btn {
  height: 30px;
  width: 30px;
  border-radius: 9999px;
  background-color: #00000033;
  border: none;
  position: relative;

  button {
    position: absolute;
    top: 74%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.btn-close:focus {
  box-shadow: none !important;
}

#deliveries_model {
    @media (max-width: 767.98px) {
      margin: auto;
      position: fixed;
      top: 36%;
      width: 90%;
      margin: 0 auto;
      transform: translate(-50%, -50%) !important;
      left: 50% !important;
      right: 0;
    }

    & .modal-content {
      overflow-x: hidden;
    }
}
