@import "@styles/variables";
@import '../styles/variable';
$y: -0px;
$m: calc(30px * -1);

// effect
@keyframes fade-down {
  0% {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}
@keyframes fade-up {
  0% {
    opacity: 0;
    transform: scaleY(0);
    visibility: hidden;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}
@keyframes zoom-In-effect {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes smooth-header {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}

// *** header ***
header {
  &.style-1 {
    position: absolute;
    width: 100%;
    z-index: 999;
    transition: all 0.8s ease-out 0s;
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &.sticky {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      box-sizing: border-box;
      background-color: $lp-dark-white-color;
      z-index: 999;
    }

    .company-logo {
      @media (max-width: 991px) {
        padding: 15px 0 !important;
      }

      .logo-dark {
        display: flex;
        flex-direction: row;
        text-decoration: none;

        p {
          margin: 0;
          font-weight: 700;
          font-size: 32px;
          color: #ffffff;
        }
      }
    }

    .sidebar-btn {
      @media (min-width: 992px) {
        display: none !important;
      }
      cursor: pointer;

      svg {
        fill: $white-color;
      }
    }

    .main-menu {
      display: inline-block;
      position: relative;

      > ul {
        list-style: none;
        margin: 0;
        padding: 0;

        > li {
          @media (max-width: 991px) {
            padding: 0px 12px !important;
          }
          @media (max-width: 1399px) {
            padding: 0 20px;
          }
          @media (min-width: 992px) {
            &:first-child {
              padding-left: 0;
              a {
                padding-left: 0;
              }
            }
            &:last-child {
              padding-right: 0;
            }
          }

          display: inline-block;
          position: relative;
          padding: 0 25px;

          &:hover {
            i {
              color: $black-color2;
              font-size: 20px;
            }

            > a {
              color: $white-color;

              &::after {
                transform-origin: left center;
                transform: scale(1, 1);
              }
            }

            &::after {
              color: $title-color;
            }
          }

          a {
            @media (min-width: 1200px) and (max-width: 1399px) {
              padding: 25.75px 0 !important;
            }

            color: $white-color;
            display: block;
            letter-spacing: 0.4px;
            padding: 25px 0px;
            position: relative;
            font-family: $font-inter;
            font-weight: 500;
            font-size: 15px;
            transition: all 0.5s ease-out 0s;
            position: relative;

            &::after {
              @media (min-width: 1200px) and (max-width: 1399px) {
                bottom: 22px !important;
              }
              @media (max-width: 991px) {
                display: none !important;
              }
              content: '';
              display: block;
              position: absolute;
              bottom: 26px;
              left: 0px;
              width: 100%;
              border-bottom: 1px solid $white-color;
              transform-origin: right center;
              transform: scale(0, 1);
              transition: transform 0.5s;

              i {
                @media (max-width: 991px) {
                  opacity: 1 !important;
                }

                font-size: 20px;
                text-align: center;
                color: $title-color;
                font-style: normal;
                position: absolute;
                right: -5px;
                top: 35px;
                z-index: 999;
                cursor: pointer;
                display: none;
                transition: all 0.5s ease-out 0s;
                opacity: 0;

                &.active {
                  color: $title-color;

                  &::before {
                    content: '\f2ea';
                  }
                }
              }
            }
          }

          &.active {
            > a {
              color: $primary-color1;

              &::before {
                @media (min-width: 1200px) and (max-width: 1399px) {
                  bottom: 22px !important;
                }
                @media (max-width: 991px) {
                  display: none !important;
                }
                content: '';
                position: absolute;
                bottom: 26px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: $primary-color1;
              }

              &::after {
                border-bottom: 1px solid $primary-color1;
              }
            }
            &::after {
              color: $primary-color1;
            }
          }

          .contact-us {
            background-color: #3067ff;
            color: #ffffff;
            border-radius: 50px;
            padding: 12px 28px;
            font-size: 16px;
            letter-spacing: 0.4px;
            font-weight: 550;
            overflow: hidden;
            cursor: pointer;
            position: relative;
            font-family: $font-inter;
            display: inline-flex;
            transition: 0.5s;
            z-index: 1;
            white-space: nowrap;

            &::after {
              position: absolute;
              content: '';
              border-radius: 30px;
              width: 100%;
              height: 100%;
              top: 0;
              right: 0;
              transform: scalex(0);
              transform-origin: right;
              transition: transform 0.7s;
              z-index: -1;
              background-color: #ffffff;
            }

            &:hover {
              color: #000000;

              &::after {
                transform: scalex(1);
                transform-origin: left;
              }
            }
          }
        }
      }
    }
  }
}

// *** sidebar ***
.sidebar-menu {
  @media (max-width: 1399px) {
    padding: 0px 0 70px !important;
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0px 0 70px;
  z-index: 9992;
  overflow-y: auto;
  transition: all 0.3s;
  background-color: $lp-dark-white-color;
  height: 100%;
  transform-origin: top;
  transform: translateY(-120%);
  transition: transform 0.6s ease-in;

  &.active {
    transform: translateY(0);
  }

  .sidebar-menu-top-area {
    padding: 25px 0;
    border-bottom: 1px solid $border-color;
    margin-bottom: 80px;

    .sidebar-menu-close {
      @media (max-width: 576px) {
        width: 36px !important;
        height: 36px !important;

        svg {
          width: 18px !important;
          height: 18px !important;
        }
      }

      border: 1px solid $white-color;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: 0.5s;

      svg {
        fill: $white-color;
        transition: 0.5s;
      }

      &:hover {
        background-color: #ff4820;
        border-color: #ff4820;

        svg {
          fill: $white-color;
        }
      }
    }
  }

  .sidebar-contact .getin-touch-area {
    h4 {
      @media (max-width: 767px) {
        margin-bottom: 25px !important;
      }
      @media (max-width: 991px) {
        margin-bottom: 30px !important;
      }

      color: $white-color;
      font-family: $font-hankenGrotesk;
      font-size: 28px;
      font-weight: 800;
      margin-bottom: 35px;

      svg {
        fill: $white-color;
        margin-left: 5px;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      .single-contact {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover .icon {
          background-color: $white-color;

          svg {
            fill: $dark-title-color;
          }
        }

        .icon {
          height: 40px;
          min-width: 40px;
          max-width: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.35s;
          border: 1px solid $white-color;

          svg {
            transition: 0.35s;
            fill: $white-color;
          }
        }

        .contact {
          span {
            color: #b5b5b5;
            font-family: $font-inter;
            font-size: 16px;
            font-weight: 600;
          }
          h6 {
            margin-bottom: 0;
            color: $white-color;

            a {
              @media (max-width: 576px) {
                font-size: 16px !important;
              }

              font-family: $font-inter;
              font-size: 18px;
              font-weight: 600;
              line-height: 1.2;
              color: $white-color;
              transition: 0.35s;
              text-decoration: none;

              &:hover {
                color: $primary-color1;
              }
            }
          }
        }
      }
    }
  }

  .sidebar-menu-wrap {
    @media (max-width: 991px) {
      margin-right: 0 !important;
    }
    @media (max-width: 1199px) {
      margin-right: 0px !important;
    }
    @media (min-width: 1200px) and (max-width: 1399px) {
      margin-right: 30px !important;
    }

    border-left: 1px solid #5e5a5a6e;
    margin-right: 50px;

    .main-menu {
      margin: 0;
      padding: 0;
      list-style: none;
      overflow-y: auto;
      border-top: 1px solid #5e5a5a6e;
      border-right: 1px solid #5e5a5a6e;

      &::-webkit-scrollbar {
        width: 0;
      }
      &::-webkit-scrollbar-track {
        background: $title-color;
      }
      &::-webkit-scrollbar-thumb {
        background: $primary-color;
      }
      > li {
        position: relative;

        > a {
          @media (max-width: 576px) {
            padding: 15px 20px !important;
            font-size: 22px !important;
          }
          @media (max-width: 767px) {
            padding: 20px !important;
          }
          border-bottom: 1px solid #5e5a5a6e;
          color: $lp-dark-title-color;
          font-family: $font-hankenGrotesk;
          text-transform: uppercase;
          font-size: 30px;
          font-weight: 700;
          display: block;
          line-height: 1;
          padding: 25px;
          transform: translateY($y) translateZ(0);
          transition: transform 0.4s ease, box-shadow 0.4s ease;
          text-decoration: none;

          .menu-text {
            display: flex;
            overflow: hidden;
            text-shadow: 0 30px 0 $title-color;

            span {
              display: block;
              backface-visibility: hidden;
              transition: transform 0.4s ease;
              transform: translateY($m) translateZ(0);

              &:nth-child(1) {
                transition-delay: 0.05s;
              }
              &:nth-child(2) {
                transition-delay: 0.1s;
              }
              &:nth-child(3) {
                transition-delay: 0.15s;
              }
              &:nth-child(4) {
                transition-delay: 0.2s;
              }
              &:nth-child(5) {
                transition-delay: 0.25s;
              }
              &:nth-child(6) {
                transition-delay: 0.3s;
              }
              &:nth-child(7) {
                transition-delay: 0.35s;
              }
              &:nth-child(8) {
                transition-delay: 0.4s;
              }
              &:nth-child(9) {
                transition-delay: 0.45s;
              }
              &:nth-child(10) {
                transition-delay: 0.5s;
              }
              &:nth-child(11) {
                transition-delay: 0.55s;
              }
              &:nth-child(12) {
                transition-delay: 0.6s;
              }
              &:nth-child(13) {
                transition-delay: 0.65s;
              }
              &:nth-child(14) {
                transition-delay: 0.7s;
              }
              &:nth-child(15) {
                transition-delay: 0.75s;
              }
              &:nth-child(16) {
                transition-delay: 0.8s;
              }
              &:nth-child(17) {
                transition-delay: 0.85s;
              }
              &:nth-child(18) {
                transition-delay: 0.9s;
              }
              &:nth-child(19) {
                transition-delay: 0.95s;
              }
              &:nth-child(20) {
                transition-delay: 1s;
              }
              &:nth-child(21) {
                transition-delay: 1.05s;
              }
              &:nth-child(22) {
                transition-delay: 1.1s;
              }
              &:nth-child(23) {
                transition-delay: 1.15s;
              }
              &:nth-child(24) {
                transition-delay: 1.2s;
              }
              &:nth-child(25) {
                transition-delay: 1.25s;
              }
              &:nth-child(26) {
                transition-delay: 1.3s;
              }
              &:nth-child(27) {
                transition-delay: 1.35s;
              }
              &:nth-child(28) {
                transition-delay: 1.4s;
              }
              &:nth-child(29) {
                transition-delay: 1.45s;
              }
              &:nth-child(30) {
                transition-delay: 1.5s;
              }
              &:nth-child(31) {
                transition-delay: 1.55s;
              }
              &:nth-child(32) {
                transition-delay: 1.6s;
              }
              &:nth-child(33) {
                transition-delay: 1.65s;
              }
              &:nth-child(34) {
                transition-delay: 1.7s;
              }
              &:nth-child(35) {
                transition-delay: 1.75s;
              }
              &:nth-child(36) {
                transition-delay: 1.8s;
              }
              &:nth-child(37) {
                transition-delay: 1.85s;
              }
              &:nth-child(38) {
                transition-delay: 1.9s;
              }
              &:nth-child(39) {
                transition-delay: 1.95s;
              }
              &:nth-child(40) {
                transition-delay: 2s;
              }
            }
          }
        }
      }
    }
  }
}

// dark mode
// body.lp-dark {
//   .sidebar-menu {
//     background-color: $lp-dark-white-color;
//   }
//   .sidebar-menu-wrap {
//     .main-menu li > a {
//       color: $lp-dark-title-color
//     }
//   }
// }
