@import "@styles/variables";
.buyer-list-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.buyer-list-row {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  align-items: center;
}

.buyer-list-col {
}

.buyer-list-item {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  color: #736E6E;
  font-weight: 400;
  padding: 4.5px 14px;
  border-radius: 23px;
  background-color: #F2F4F7;
  min-width: 100px;
  text-align: center;
}

.buyer-list-button {
  padding: 7px 14px;
  border-radius: 23px;
  background: none;
  color: black;
  cursor: pointer;
  flex-shrink: 0;
  border: 1px solid #D0D5DD;
}
.btn-show-all {
  display: flex;
  align-items: center;
  background: none;
  font-size: 14px;
  right: 80px;
  font-weight: 800;
  border: none;
  cursor: pointer;
  &:hover {
    filter: brightness(50%);
  }
}
.btn-show {
  display: flex;
  align-items: center;
  padding: 2.5px 10px;
  border-radius: 25px;
  border: 1px solid #33AAFF;
  background: none;
  font-size: 12px;
  right: 80px;
  color: #33AAFF;
  font-weight: 500;

}

.btn-show svg {
  width: 40px;
  height: 40px;
  stroke: #33AAFF;
}