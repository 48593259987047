.custom-dropdown-toggle {
    width: 100%;  
    text-align: start !important;  
    background-color: white !important;
    color:black !important
  }
  
  .custom-dropdown-menu {
    width: 50%;  
    /* color: black; */
  }

.res{
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.bor{
  border: unset !important;
}
 
.bgClass {
  /* color: var(--bs-dropdown-link-hover-color); */
  /* background-color: #c0d9ff !important; */
  background: #F5F6F7 !important;
  color: #0080FC;

}
.bgClass:hover {
  background: #F5F6F7 !important;
  color: #0080FC ;
}


  /* Small Devices, Tablets */
  @media only screen and (max-width : 575px) {
.addVehicle .modal-content {
  position: absolute;
  bottom: 0;

}
.addVehicle.modal.show .modal-dialog {
  margin-top: 15px !important;
}

.generic-modal-footer2{
  justify-content: space-between !important;
  background-color: #E1EFFE;
}

.generic_apply_button2{
  width: 47% !important;
}
.edit_cancel_button2{
  width: 47% !important;
}

.chip{
  background: #F6F6F6;
  padding: 10px;
}
.roleModalInput{
  background-color: #EAECF0 ;
  padding-left: 32px;
}
 
 /* .vehicle-model{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;            
 } */

.flexClass{

  display: grid ;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;    
  }

  .generic-modal-footer2 .bgNone{
    background-color: none !important;
  }
}

.roles-container {
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 3px;
    background-color: #e4e4e4 !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b6b6b6;
    border-radius: 999px !important;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #b6b6b6;
    cursor: pointer;
  }
}

.custom-btn-1 {
  border: solid 1px #EAECF0;
  border-radius: 8px;
  height: 42px;
  color: #1D2939;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  background-color: #ffffff;

  @media (max-width: 900px) {
    width: 47%;
  }
  @media (min-width: 900px) {
    width: 30%;
  }
}

.custom-btn-2 {
  border: solid 1px #0080FC;
  border-radius: 8px;
  height: 42px;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  background-color: #0080FC;

  @media (max-width: 900px) {
    width: 47%;
  }
  @media (min-width: 900px) {
    width: 30%;
  }
}

.custom-edit-roles-box {
  background-color: #F6F6F6;
  border: dashed 1px #D0D5DD;
  border-radius: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0px 14px;
  cursor: pointer;
}

.editRoleInputContainer {
  background-color: #F6F6F6 !important;
  border: dashed 1px #D0D5DD;
  border-radius: 8px;
  height: 40px;
}

.editRoleInput {
  background-color: #F6F6F6 !important;
  border: none !important;
  padding: 0px;
  height: 38px;
  max-width: 190px !important;
  min-width: 50px !important;
  padding: 0px 10px;
}