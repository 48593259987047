@import "@styles/variables";
@import '@styles/variables';
@import '@styles/mixins';
.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28.9px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #D0D5DD;
  transition: .4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20.9px;
  width: 20.9px;
  border-radius: 20px;
  left: 5px;
  bottom: 4px;
  background-color: #FFFFFF;
  transition: .4s;
  box-shadow: 0 2.32px 0.77px rgba(0, 0, 0, 0.06), 0px 2.32px 6.19px rgba(0, 0, 0, 0.15);
}

input:checked + .slider {
  background-color: #0080FC;
}

input:focus + .slider {
  box-shadow: 0 2.32px 0.77px rgba(0, 0, 0, 0.06), 0px 2.32px 6.19px rgba(0, 0, 0, 0.15);
}

input:checked + .slider:before {
  transform: translateX(1.4em);
  background-color: #fff;
  box-shadow: 0 2.32px 0.77px rgba(0, 0, 0, 0.06), 0px 2.32px 6.19px rgba(0, 0, 0, 0.15);
}

/* Disabled state */
.switch input:disabled + .slider {
  background-color: #D0D5DD;
  cursor: not-allowed;
}

.switch input:disabled + .slider:before {
  background-color: #ffff;
  box-shadow: none;
}

.nav-header-sms {
  @include flex-container(row, $justify: space-between, $align: center);
  padding: 0px 12px;
  background: #f9fafb;
  border-bottom: 1px solid #e6e8ec;
  border-radius: 8px 8px 0 0;
}

.wrapper-buyer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
  padding: 2px 16px;
  border-radius: 8px;
}
.wrapper-buyer.show-all {
  flex-wrap: wrap;
}
.bg-light-gray {
  background-color: #F2F4F7;
  font-size: 14px;
  color: #736E6E;
}
.buyer-tag.hidden {
  display: none;
}
.wrapper {
  border: 1px solid #E6E8EC;
}
.ptb {
  margin-top: 8.5px;
  margin-bottom: 8.5px;
}
.buyers-list {
  margin: 0 15px;
  display: inline-flex;
  white-space: nowrap;
}
.buyer-tag-item {
  display: inline-flex;
  padding: 6.5px 14px;
  background: #F2F4F7;
  border-radius: 23px;
  color: #736E6E;
}

.buyer-tag {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
}
.buyer-tag .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: transparent;
  svg {
    width: 9px;
    height: 9px;
    stroke: #667085;
    margin-left: 7px;
  }
}


.titleExcept {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #667085;
}

.buyer-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
}

.buyer-exceptions {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.add-buyer-btn, .more-buyers-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0 10px;
  border-radius: 3px;
  cursor: pointer;
}

.add-buyer-btn {
  margin-right: 10px;
}

.more-buyers-btn {
  background-color: #17a2b8;
}

.hidden {
  display: none;
}
.btn-add {
  display: flex;
  align-items: center;
  background: none;
  color: #667085;
  border-radius: 25px;
  border: 1px solid #efefef;
  font-size: 12px;
  font-weight: 500;
  transition: background-color 0.3s ease, color 0.3s ease;
  white-space: nowrap;
  padding: 5.3px 14px;
  cursor: pointer;
}
.btn-add:disabled {
  cursor: not-allowed;
}
.disabled {
  cursor: not-allowed;
}
.btn-add svg {
  width: 12px;
  height: 12px;
  stroke: #667085;
  margin-right: 8px;
  margin-left: 0;
}
.btn-add:hover {
  background-color: #efefef;
  border-color: #efefef;
}

.btn-link {
  color: #6b7280;
  font-size: 12px;
  padding: 0;
  text-decoration: none;
}

.btn-link:hover {
  text-decoration: underline;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 2);
}
.modal-content {
  border: none;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

.modal-header {
  border-bottom: none;
}

.form-select {
  border-radius: 8px;
  font-size: 14px;
  padding: 10px 12px;
  color: #667085;
}

button.btn-primary {
  border-radius: 8px;
  background-color: #007bff;
  padding: 8px 16px;
}
.modal-header .btn-close {
  font-size: 13.5px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
}
.button-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
}
.custom-select {
  appearance: none;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  padding: 10px 12px;
  font-size: 14px;
  color: #667085;
  background-color: #fff;
  background-image: url('data:image/svg+xml;base64,...');
  background-position: right 12px center;
  background-repeat: no-repeat;
  background-size: 12px;
}

.custom-select option {
  padding: 10px;
}

.custom-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.custom-select option {
  padding: 8px 12px;
  background-color: #fff;
}

.custom-select option:hover {
  background-color: #f8f9fa;
}

.custom-select option:checked {
  background-color: #e7f1ff;
  color: #344054;
}

.custom-dropdown {
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f9fa;
  color: #495057;
}

.custom-dropdown option {
  padding: 8px;
}

.custom-dropdown option:hover {
  background-color: #e9ecef;
}

.custom-dropdown option:focus {
  background-color: #e9ecef;
}
.modal-footer-sms {
  background-color: #FAFAFC;
  border: none;
  padding: 12px;
  display: flex;
  justify-content: end;
  border-radius: 12px;
}

.modal-footer-sms-button {
  background-color: #0080FC;
  font-size: 14px;
  padding: 11px 27px;
  font-weight: 400;
  border-radius: 8px;
  border: none;
  color: white;
  width: 86px;
  height: 46px;
}
.vertical-bar {
  display: flex;
  width: 2px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 20px;
  margin: 0 auto;
}


.custom-modal .modal-content {
  border-radius: 12px !important;
}
.modal {
  border-radius: 12px;
}
.wrapperOtp {
  display: flex;
  flex-direction: row;
  gap: 15px;
    align-items: center;
    justify-content: center;
}



.sendOtp {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #667085;
  font-weight: 500;
  font-size: 14px;
  background: none;
}

.labelOn,
.labelOff {
  display: flex;
  align-items: center;
  color: #667085;
  font-size: 12px;
  gap: 4px;
}



.custom-radio-off {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 1px solid #98A2B3;
    border-radius: 50%;
    position: relative;
}
input[type="radio"]:checked + .custom-radio-off:before {
  content: '';
  width: 4px;
  height: 4px;
  background-color: #98A2B3;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-radio-on {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #98A2B3;
  border-radius: 50%;
  position: relative;
}
input[type="radio"]:checked + .custom-radio-on {
  border: 1px solid #0080FC;
}
input[type="radio"]:checked + .custom-radio-on:before {
  content: '';
  width: 4px;
  height: 4px;
  background-color: #0080FC;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.custom-radio-on.active {
  border-color: #0080FC;
}
.custom-radio-on.active:before {
  content: '';
  width: 4px;
  height: 4px;
  background-color: #0080FC;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.custom-radio-off.active:before {
  content: '';
  width: 4px;
  height: 4px;
  background-color: #98A2B3;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
input[type="radio"] {
  opacity: 0;
  position: absolute;
}
.toast-centered {
  text-align: center;
}

//=============

.testcard {
  border: 1px black solid;
  padding: 10px;
}

//============= BuyeList.scss
