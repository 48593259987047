@import "@styles/variables";
@import '../../styles/variable';

.circle-container {
  background: transparent;
  position: fixed;
  right: 30px;
  bottom: 55px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px #3067ff;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  // icon
  //   &::after {
  //     position: absolute;
  //     font-family: 'Bootstrap-icons';
  //     content: '\F145';
  //     text-align: center;
  //     line-height: 40px;
  //     font-size: 28px;
  //     color: $primary-color1;
  //     left: 0;
  //     top: 0;
  //     height: 40px;
  //     width: 40px;
  //     cursor: pointer;
  //     display: block;
  //     z-index: 1;
  //     -webkit-transition: all 200ms linear;
  //     transition: all 200ms linear;
  //   }

  // circle container hover
  &:hover {
    background-color: #3067ff;
    // icon hover
    svg {
      path {
        fill: $white-color !important;
        transition: 0.5s;
      }
    }
    &::after {
      color: $white-color;
    }
  }
  svg {
    path {
      fill: $primary-color1;
      -webkit-transition: all 200ms linear;
      transition: all 200ms linear;
    }
  }
}
