@import "@styles/variables";
.vehicle-icon-box {
    border-radius: 8px;
    box-shadow: 0px 0px 24px 4px #0D99FF14 inset;
    @media (max-width: 900px) {
        width: 50px;
        height: 50px;
    }
    @media (min-width: 900px) {
        width: 70px;
        height: 70px;
    }

    .vehicle-icon {
        @media (max-width: 900px) {
            width: 32px;
            height: 32px;
        }
        @media (min-width: 900px) {
            width: 42px;
            height: 42px;
        }
    }
}